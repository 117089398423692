import {EntityId, UrlString, UnixTimeStamp, LocalizableString} from 'shared/types';
import {Country} from 'domain/country';
import {ClientImage} from 'domain/image';
import {createdId} from 'utils/createId';

export type WeightGrams = number;
export type PriceCents = number;
export type StockNumber = string | number;
export type Tag = string;

export enum Status {
  Unknown,
  InStock,
  ForSale,
  Purchased,
  Lost,
}

export type Condition = 1 | 2 | 3 | 4 | 5;

export type Attachments = {
  images: ClientImage[];
};

export interface Product {
  id: EntityId;
  name: LocalizableString;
  description: LocalizableString;
  stockNumber: StockNumber;
  attachments: Attachments;
  condition: Condition;
  price: PriceCents;
  purchasePrice: PriceCents;
  weight: WeightGrams;
  tags: Tag[];

  brand?: string;
  extraInfoUrl?: UrlString;
  productionYear?: number;
  productionCountry?: Country;
  extendedDescription?: string;

  createdAt: UnixTimeStamp;
  status: Status;
}

export function createEmpty(): Product {
  return {
    id: createdId(),
    name: '',
    description: '',
    stockNumber: 0,
    weight: 0,
    price: 0,
    purchasePrice: 0,
    condition: 3,
    tags: [],
    attachments: {
      images: [],
    },

    createdAt: Date.now(),
    status: Status.Unknown,
  };
}
