import React from 'react';
import {Typography} from 'antd';
import {connect} from 'react-redux';

import {TagsSectionProps} from '../types';
import {mapStateToProps, toggleTagToProps as mapDispatchToProps} from '../connect';
import {hasTag} from 'store/currentProduct';

import Tag from 'components/Tag';
import Translate from './translation.json';
import topicTags from './topicTags';
import timeTags from './timeTags';
import './style.css';

const {Title} = Typography;

const Tags: React.FC<TagsSectionProps> = ({currentProduct: product, toggleTag}) => {
  return (
    <article className='add-product-tags'>
      <section className='add-product-tags-section'>
        <Title level={4}>{Translate.topic}</Title>
        {topicTags.map((tag) => (
          <Tag checkable checked={hasTag(tag, product)} onChange={() => toggleTag(tag)} key={tag}>
            {tag}
          </Tag>
        ))}
      </section>
      <section className='add-product-tags-section'>
        <Title level={4}>{Translate.time}</Title>
        {timeTags.map((tag) => (
          <Tag checkable checked={hasTag(tag, product)} onChange={() => toggleTag(tag)} key={tag}>
            {tag}
          </Tag>
        ))}
      </section>
    </article>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tags);
