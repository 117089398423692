import React from 'react';
import {connect} from 'react-redux';
import {Form, Input} from 'antd';

import {StateIndex} from 'store/currentProduct';
import {ValidatableSectionProps, UpdateValueEvent} from '../types';
import {mapStateToProps, updateToProps as mapDispatchToProps} from '../connect';

import CountrySelector from 'components/CountrySelector';

import {injectIf} from 'utils/injectIf';
import Translate from './translation.json';

const Optional: React.FC<ValidatableSectionProps> = ({
  currentProduct: product,
  update,
  errors,
  removeError,
}) => {
  const updateValue = (e: UpdateValueEvent): void => {
    const {name, value} = e.target;
    removeError(name);
    return update(name as StateIndex, value);
  };

  return (
    <section>
      <Form.Item
        label={Translate.productionCountry}
        validateStatus={injectIf(!!errors.productionCountry, 'error')}>
        <CountrySelector
          name='productionCountry'
          selectedCountry={product.productionCountry}
          onChange={updateValue}
        />
      </Form.Item>

      <Form.Item
        label={Translate.extraInfoUrl}
        validateStatus={injectIf(!!errors.extraInfoUrl, 'error')}>
        <Input
          type='url'
          maxLength={200}
          placeholder='www.fallout.gamepedia.com/Nuka-Cola'
          name='extraInfoUrl'
          value={product.extraInfoUrl}
          onChange={updateValue}
        />
      </Form.Item>

      <Form.Item label={Translate.brand} validateStatus={injectIf(!!errors.brand, 'error')}>
        <Input placeholder='Nuka-cola' name='brand' value={product.brand} onChange={updateValue} />
      </Form.Item>

      <Form.Item
        label={Translate.productionYear}
        validateStatus={injectIf(!!errors.productionYear, 'error')}>
        <Input
          type='number'
          placeholder='2058'
          name='productionYear'
          value={product.productionYear}
          onChange={updateValue}
        />
      </Form.Item>
    </section>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Optional);
