import {put, call} from 'redux-saga/effects';
import {AsyncActionTypes} from 'store/types';

type InvokableMethod = (...args: any[]) => any;

// TODO: enrich yields with data from server
export function* callApi(
  apiMethod: InvokableMethod,
  actionType: AsyncActionTypes,
  actionPayload?: unknown
) {
  const {RESOLVE, FAILURE} = actionType;

  try {
    const {status} = yield call(apiMethod, {data: actionPayload});
    const succeeded = status === 200;

    if (succeeded) yield put({type: RESOLVE, payload: null});
    else yield put({type: FAILURE, payload: null});

    return succeeded;
  } catch (error) {
    console.error('[SAGA]', error);
    yield put({type: FAILURE, payload: error.message});
    return false;
  }
}
