import React from 'react';
// import {connect} from 'react-redux';
// import {Link} from 'react-router-dom';
import {Layout} from 'antd';

// import {User} from 'domain/user';
// import {WithUserState, hasUser} from 'store/user';
// import {AppState} from 'store/types';

// import Logo from 'components/Logo';
// import Translate from './translation.json';
import './style.css';

const {Header: LayoutHeader} = Layout;

const Header: React.FC = () => {
  return (
    <LayoutHeader className='header'>
      {/* <Logo />
      {!hasUser(user) ? (
        <Link to='/signin'>{Translate.signin}</Link>
      ) : (
        <Link to='/profile'>{(user as User).name}</Link>
      )} */}
    </LayoutHeader>
  );
};

// const mapStateToProps = (state: AppState): WithUserState => ({
//   user: state.user,
// });

// export default connect(mapStateToProps)(Header);
export default Header;
