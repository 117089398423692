import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Icon, Button} from 'antd';

import {AppState} from 'store/types';
import {WithUserState, isAdmin} from 'store/user';
import './style.css';

const NavBar: React.FC<WithUserState> = ({user}) => {
  return (
    <nav className='nav'>
      {isAdmin(user) && (
        <Link to='/add'>
          <Button type='default' shape='circle' size='large'>
            <Icon type='plus' />
          </Button>
        </Link>
      )}
    </nav>
  );
};

const mapStateToProps = (state: AppState): WithUserState => ({
  user: state.user,
});

export default connect(mapStateToProps)(NavBar);
