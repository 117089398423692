import React from 'react';
import {Country} from 'domain/country';
import {countriesList} from 'shared/countries';
import Translate from './translation.json';

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  selectedCountry?: Country;
}

const CountrySelector: React.FC<Props> = ({selectedCountry, ...rest}) => {
  const value = selectedCountry ? selectedCountry.code : 'not-selected';

  return (
    <select value={value} {...rest}>
      <option value='not-selected'>{Translate.notSelected}</option>
      {countriesList.map(({name, code}: Country) => (
        <option key={code} value={code}>
          {name}
        </option>
      ))}
    </select>
  );
};

export default CountrySelector;
