import React from 'react';
import {CompactCard} from 'components/ProductCard';
import {Product} from 'domain/product';
import './style.css';

interface Props {
  products: Product[];
}

const ProductList: React.FC<Props> = ({products}) => {
  return (
    <div className='productlist'>
      {products.map((product) => (
        <CompactCard key={product.id} product={product} />
      ))}
    </div>
  );
};

export default ProductList;
