import React from 'react';
import Logo from 'assets/logo.png';
import './style.css';

const Splash = () => {
  return (
    <div className='splash'>
      <img src={Logo} alt='logo' />
    </div>
  );
};

export default Splash;
