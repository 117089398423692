import {createEmpty, Tag} from 'domain/product';
import {AnyAction} from 'store/types';
import {UPDATE, TOGGLE_TAG} from './actions';
import {State, UpdatePayload} from './types';
import {hasTag} from './selectors';

const initialState = createEmpty();

export function currentProduct(state: State = initialState, {type, payload}: AnyAction): State {
  switch (type) {
    case UPDATE: {
      const {field, value} = payload as UpdatePayload;
      return {...state, [field]: value};
    }

    case TOGGLE_TAG: {
      const tag = payload as Tag;
      const {tags} = state;

      const newTags = hasTag(tag, state) ? tags.filter((t) => t !== tag) : [...tags, tag];
      return {...state, tags: newTags};
    }

    default:
      return state;
  }
}
