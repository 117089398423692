import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Form, Button, Divider} from 'antd';

import {AppState} from 'store/types';
import {WithProductState, State} from 'store/currentProduct';

import {ErrorsIndex} from 'interactors/validation';
import {withPreventDefault} from 'utils/withPreventDefault';
import {omit} from 'utils/omit';

import Mandatory from './Sections/Mandatory';
import Optional from './Sections/Optional';
import Tags from './Sections/Tags';

import {validate} from './validate';
import Translate from './translation.json';

interface OwnProps {
  onSubmit(product: State): void;
}

const AddProductForm: React.FC<WithProductState & OwnProps> = ({
  currentProduct: product,
  onSubmit,
}) => {
  const [errors, setErrors] = useState<ErrorsIndex>({});

  const removeError = (field?: string) => {
    if (!field || !errors[field]) return;
    setErrors(omit<string>(errors, [field]));
  };

  const validateAndSave = (): void => {
    const {isValid, errors} = validate(product);
    setErrors(errors);

    if (!isValid) return;
    return onSubmit(product);
  };

  return (
    <Form layout='vertical' onSubmit={withPreventDefault(validateAndSave)} noValidate>
      <Mandatory errors={errors} removeError={removeError} />
      <Divider />
      <Optional errors={errors} removeError={removeError} />
      <Divider />
      <Tags />

      <Button className='submit-btn' size='large' type='primary' htmlType='submit'>
        {Translate.save_btn_label}
      </Button>
    </Form>
  );
};

const mapStateToProps = (state: AppState): WithProductState => ({
  currentProduct: state.currentProduct,
});

export default connect(mapStateToProps)(AddProductForm);
