import React from 'react';
import {Link} from 'react-router-dom';
import {Typography} from 'antd';
import Nobr from 'components/Nobr';
import StaticPage from '..';

const {Title, Paragraph} = Typography;

const AgreementEn = () => {
  return (
    <StaticPage title='Пользовательское соглашение'>
      <Paragraph>
        Данное Соглашение вступает в&nbsp;силу с&nbsp;момента Вашего согласия с&nbsp;его условиями
        при регистрации на&nbsp;Hobius
      </Paragraph>

      <Title level={3}>1. Предмет&nbsp;Соглашения</Title>
      <Paragraph>
        Пользовательское Соглашение является юридически обязывающим договором и&nbsp;регламентирует
        отношения между Администрацией интернет-ресурса Hobius, расположенного по&nbsp;адресу&nbsp;
        <Link to='https://www.hobius.ru'>https://www.hobius.ru</Link>, (Далее&nbsp;— «Администрация»
        или «Hobius») и&nbsp;физическим лицом (далее&nbsp;— «Пользователь»), использующим сайт для
        получения, публикации или поиска&nbsp;информации.
      </Paragraph>

      <Title level={3}>2. Описание&nbsp;услуг</Title>
      <Paragraph>
        2.1. Hobius является публичным информационно-коммуникационным сервисом и&nbsp;предоставляет
        свои услуги на&nbsp;условиях, являющихся предметом настоящего Пользовательского Соглашения
        (ПС). Соглашение может быть изменено нами без <Nobr>какого-либо</Nobr> специального
        уведомления, новая редакция Соглашения вступает в&nbsp;силу по&nbsp;истечении 3 (трёх) дней
        с&nbsp;момента её размещения, если иное не&nbsp;предусмотрено новой редакцией Соглашения.
        Действующая редакция ПС&nbsp;располагается по&nbsp;адресу&nbsp;
        <Link to='/help/tos/ru/'>https://www.hobius.ru/help/tos/ru/</Link>.
      </Paragraph>
      <Paragraph>
        2.2. После регистрации Вы&nbsp;получите в&nbsp;личное распоряжение дисковое пространство для
        размещения текстов, фотоматериалов, сообщений или информации, касающейся или ссылающейся
        на&nbsp;программы, музыку, звуки, фотографии, графические изображения или видео
        (далее&nbsp;— «Содержание»). Содержание размещается на&nbsp;серверах&nbsp;Администрации.
      </Paragraph>
      <Paragraph>
        2.3. Вы&nbsp;понимаете и&nbsp;соглашаетесь с&nbsp;тем, что все услуги предоставляются «как
        есть» и&nbsp;что Hobius не&nbsp;несёт ответственности ни&nbsp;за&nbsp;какие задержки, сбои,
        неверную или несвоевременную доставку, удаление или несохранность <Nobr>какой-либо</Nobr>{' '}
        пользовательской персональной информации.&nbsp;
      </Paragraph>

      <Title level={3}>3. Ваши обязательства&nbsp;по&nbsp;регистрации</Title>
      <Paragraph>
        3.1. Для того чтобы воспользоваться услугами ресурса Hobius, Вы&nbsp;соглашаетесь
        предоставить правдивую, точную и&nbsp;полную информацию о&nbsp;себе по&nbsp;вопросам,
        предлагаемым в&nbsp;Регистрационной форме, и&nbsp;поддерживать эту информацию
        в&nbsp;актуальном&nbsp;состоянии.
      </Paragraph>
      <Paragraph>
        3.2. Если Вы&nbsp;предоставляете неверную информацию или у&nbsp;Администрации имеются
        серьёзные основания полагать, что предоставленная Вами информация неверна, неполна или
        неточна, Администрация имеет право приостановить либо отменить Вашу регистрацию
        и&nbsp;отказать Вам в&nbsp;использовании интернет-ресурса Hobius (либо его части) без
        предварительного уведомления и&nbsp;объяснения&nbsp;причин.
      </Paragraph>
      <Paragraph>
        3.3. В&nbsp;процессе регистрации Вы&nbsp;указываете логин и&nbsp;пароль для доступа
        к&nbsp;персонализированной части Hobius. Вы&nbsp;несёте ответственность за&nbsp;безопасность
        Вашего логина и&nbsp;пароля, а&nbsp;также за&nbsp;всё, что будет сделано на&nbsp;Hobius под
        Вашим логином и&nbsp;паролем. Администрация имеет право запретить использование опредёленных
        логинов и/или изъять их&nbsp;из&nbsp;обращения. Вы&nbsp;соглашаетесь с&nbsp;тем, что
        Вы&nbsp;обязаны немедленно уведомить Администрацию о&nbsp;любом случае неавторизованного
        (не&nbsp;разрешенного Вами) доступа с&nbsp;Вашим логином и&nbsp;паролем и/или о&nbsp;любом
        нарушении безопасности, а&nbsp;также с&nbsp;тем, что Вы&nbsp;самостоятельно осуществляете
        завершение работы под своим паролем (кнопка «Выход») по&nbsp;окончании каждой сессии работы
        с&nbsp;сайтом. Администрация не&nbsp;отвечает за&nbsp;возможную потерю или порчу данных,
        которая может произойти <Nobr>из-за</Nobr> нарушения Вами положений этой части ПС.&nbsp;
      </Paragraph>

      <Title level={3}>4. Положение о&nbsp;поведении&nbsp;Пользователя</Title>
      <Paragraph>
        4.1. Вы&nbsp;понимаете и&nbsp;принимаете, что за&nbsp;всю информацию (Содержание)&nbsp;—
        данные, текст, программы, музыку, звуки, фотографии, графику, видео, сообщения и&nbsp;другие
        материалы, размещённые для общего доступа или переданные в&nbsp;частном порядке,
        —&nbsp;ответственно то&nbsp;лицо, которое это Содержание произвело. Это означает, что Вы,
        а&nbsp;не&nbsp;Администрация, полностью отвечаете за&nbsp;всё Содержание, который
        Вы&nbsp;загружаете, посылаете, передаёте или <Nobr>каким-либо</Nobr> другим способом делаете
        доступным с&nbsp;помощью Hobius.
      </Paragraph>
      <Paragraph>
        4.2. Пользователь соглашается с&nbsp;тем, что Содержание, размещаемое
        им&nbsp;на&nbsp;Hobius, будет доступно для всех других пользователей, как непосредственно
        на&nbsp;Сайте, так и&nbsp;путём трансляции различными техническими средствами
        со&nbsp;ссылкой на&nbsp;первоначальный источник (с&nbsp;помощью почтовой рассылки,{' '}
        <Nobr>RSS-трансляции</Nobr>, использования в&nbsp;качестве электронной открытки,
        скринсейвера, записи в&nbsp;блоге и&nbsp;т.д.).
      </Paragraph>
      <Paragraph>
        4.3. Администрация не&nbsp;контролирует Содержание, передаваемое через сервисы Hobius, и,
        следовательно, не&nbsp;гарантирует точность, полноту или качество этого Содержания.
        Вы&nbsp;понимаете, что, используя сервисы Hobius, Вы&nbsp;можете увидеть Содержание, которое
        является оскорбительным, недостойным или спорным. Ни&nbsp;при каких обстоятельствах
        Администрация не&nbsp;несёт ответственности за&nbsp;Содержание, созданное пользователями
        Hobius.
      </Paragraph>
      <Paragraph>
        4.4. Права на&nbsp;всё размещенное на&nbsp;Hobius Содержание считаются принадлежащими
        разместившим их&nbsp;Пользователям до&nbsp;тех пор, пока не&nbsp;будет оснований считать
        иначе. Пользователи не&nbsp;получают никаких прав на&nbsp;использование Содержания других
        Пользователей вне рамок возможностей, предоставляемых Hobius, и&nbsp;несут полную
        ответственность перед автором за&nbsp;неправомерное использование&nbsp;Содержания.
      </Paragraph>
      <Paragraph>
        4.5. Пользователь самостоятельно несёт ответственность перед третьими лицами за&nbsp;свои
        действия, связанные с&nbsp;созданием и&nbsp;размещением Содержания на&nbsp;Hobius,
        в&nbsp;том числе за&nbsp;то, что Cодержание соответствует требованиям законодательства
        РФ&nbsp;и&nbsp;не&nbsp;нарушает права и&nbsp;законные интересы третьих лиц. Содержание
        в&nbsp;целом и&nbsp;каждый из&nbsp;его элементов не&nbsp;должны нарушать авторские права,
        права на&nbsp;товарные знаки, средства индивидуализации и/или права на&nbsp;иные объекты
        интеллектуальной собственности, принадлежащие третьим лицам. Пользователь самостоятельно
        и&nbsp;за&nbsp;свой счёт обязуется урегулировать все претензии третьих лиц, связанные
        с&nbsp;созданием и&nbsp;размещением Содержания на&nbsp;интернет-ресурсе Hobius.
      </Paragraph>
      <Paragraph>4.6. Вы&nbsp;обязуетесь не&nbsp;использовать Hobius для:&nbsp;</Paragraph>
      <ul>
        <li>
          загрузки, посылки, передачи или любого другого способа размещения Содержания, которое
          является незаконным, вредоносным, угрожающим, клеветническим, оскорбляет нравственность,
          нарушает авторские права, пропагандирует ненависть и/или дискриминацию людей
          по&nbsp;расовому, этническому, половому, религиозному, социальному признакам, содержит
          оскорбления в&nbsp;адрес конкретных лиц или&nbsp;организаций;
        </li>
        <li>
          нарушения прав несовершеннолетних лиц и/или причинение им&nbsp;вреда
          в&nbsp;любой&nbsp;форме;
        </li>
        <li>ущемления прав&nbsp;меньшинств;</li>
        <li>
          выдавания себя за&nbsp;другого человека или представителя организации и/или сообщества без
          достаточных на&nbsp;то&nbsp;прав, в&nbsp;том числе за&nbsp;сотрудников Администрации,
          за&nbsp;модераторов форумов, за&nbsp;владельца сайта, а&nbsp;также введения
          в&nbsp;заблуждение относительно свойств и&nbsp;характеристик&nbsp;<Nobr>каких-либо</Nobr>{' '}
          субъектов или&nbsp;объектов;
        </li>
        <li>
          загрузки, посылки, передачи или любого другого способа размещения Содержания, которое
          Вы&nbsp;не&nbsp;имеете права делать доступным по&nbsp;закону РФ&nbsp;или согласно&nbsp;
          <Nobr>каким-либо</Nobr> контрактным&nbsp;отношениям;
        </li>
        <li>
          загрузки, посылки, передачи или любого другого способа размещения Содержания, которое
          затрагивает&nbsp;<Nobr>какой-либо</Nobr> патент, торговую марку, коммерческую тайну,
          копирайт или прочие права собственности и/или авторские и&nbsp;смежные с&nbsp;ним права
          третьей&nbsp;стороны;
        </li>
        <li>
          загрузки, посылки, передачи или любого другого способа размещения не&nbsp;разрешенной
          специальным образом рекламной информации, спама (в&nbsp;том числе и&nbsp;поискового),
          списков чужих адресов электронной почты, схем «пирамид», многоуровневого (сетевого)
          маркетинга (MLM), систем интернет-заработка и&nbsp;<Nobr>e-mail-бизнесов</Nobr>, «писем
          счастья», а&nbsp;также для участия в&nbsp;этих мероприятиях (отправка несогласованных
          писем со&nbsp;ссылками на&nbsp;предоставляемые Администрацией сервисы) может считаться
          участием в&nbsp;запрещённых настоящим пунктом мероприятиях, даже если отправка
          производилась без непосредственного использования серверов Hobius);
        </li>
        <li>
          загрузки, посылки, передачи или любого другого способа размещения <Nobr>каких-либо</Nobr>{' '}
          материалов, содержащих вирусы или&nbsp;другие компьютерные коды, файлы или программы,
          предназначенные для нарушения, уничтожения либо ограничения функциональности любого
          компьютерного или телекоммуникационного оборудования или программ, для осуществления
          несанкционированного доступа, а&nbsp;также серийные номера к&nbsp;коммерческим программным
          продуктам и&nbsp;программы для их&nbsp;генерации, логины, пароли и&nbsp;прочие средства
          для получения несанкционированного доступа к&nbsp;платным ресурсам в&nbsp;Интернете,
          а&nbsp;также размещения ссылок на&nbsp;вышеуказанную&nbsp;информацию;
        </li>
        <li>
          умышленного нарушения местного, российского законодательства или норм
          международного&nbsp;права;
        </li>
        <li>сбора и&nbsp;хранения персональных данных других&nbsp;лиц;</li>
        <li>нарушения нормальной работы веб-сайта Hobius;</li>
        <li>
          применения любых форм и&nbsp;способов незаконного представительства других
          лиц&nbsp;в&nbsp;Сети;
        </li>
        <li>
          размещения ссылок на&nbsp;ресурсы Сети, содержание которых противоречит действующему
          законодательству&nbsp;РФ;
        </li>
        <li>
          содействия действиям, направленным на&nbsp;нарушение ограничений и&nbsp;запретов,
          налагаемых&nbsp;Соглашением.
        </li>
      </ul>

      <Paragraph>
        4.7. Вы&nbsp;признаёте, что Администрация не&nbsp;обязана просматривать Содержание любого
        вида перед размещением, а&nbsp;также то, что Администрация имеет право
        (но&nbsp;не&nbsp;обязанность) по&nbsp;своему усмотрению отказать в&nbsp;размещении или
        удалить любое Содержание, которое доступно через сайт Hobius. Вы&nbsp;согласны с&nbsp;тем,
        что Вы&nbsp;должны самостоятельно оценивать все риски, связанные с&nbsp;использованием
        Содержания, включая оценку надёжности, полноты или полезности этого Содержания.&nbsp;
      </Paragraph>
      <Paragraph>
        4.8. Содержание интернет-ресурса Hobius&nbsp;может содержать ссылки на&nbsp;другие ресурсы.
        Вы&nbsp;признаёте и&nbsp;соглашаетесь с&nbsp;тем, что Администрация не&nbsp;несёт никакой
        ответственности за&nbsp;доступность этих ресурсов и&nbsp;за&nbsp;их&nbsp;содержание,
        а&nbsp;также за&nbsp;любые последствия, связанные с&nbsp;использованием этих&nbsp;ресурсов.
      </Paragraph>
      <Paragraph>
        4.9. Вы&nbsp;понимаете, что технология работы служб может потребовать передачи Вашего
        Содержания по&nbsp;компьютерным сетям, а&nbsp;также изменения его для соответствия
        техническим требованиям.&nbsp;
      </Paragraph>
      <Paragraph>
        4.10. Вы&nbsp;соглашаетесь не&nbsp;воспроизводить, не&nbsp;повторять
        и&nbsp;не&nbsp;копировать, не&nbsp;продавать и&nbsp;не&nbsp;перепродавать, а&nbsp;также
        не&nbsp;использовать для <Nobr>каких-либо</Nobr> коммерческих целей <Nobr>какие-либо</Nobr>{' '}
        части сайта Hobius, кроме тех случаев, когда такое разрешение дано Вам Администрацией
        в&nbsp;письменной форме. Зарегистрировавшись, Вы&nbsp;получаете непередаваемое право
        пользоваться своей регистрацией (логином и&nbsp;паролем) для доступа к&nbsp;своей
        персональной информации. Вы&nbsp;не&nbsp;имеете права передавать свою регистрацию (логин
        и&nbsp;пароль) третьему лицу, а&nbsp;также не&nbsp;имеете права получать его
        от&nbsp;третьего лица иначе, чем с&nbsp;письменного согласия Администрации. Администрация
        не&nbsp;несёт никакой ответственности ни&nbsp;по&nbsp;каким договорам между Вами
        и&nbsp;третьими лицами.&nbsp;
      </Paragraph>
      <Paragraph>
        4.11. Вы&nbsp;соглашаетесь с&nbsp;тем, что всё Содержание, размещённое Вами на&nbsp;Hobius,
        включая тексты, фотоматериалы, комментарии, сообщения в&nbsp;форумах и&nbsp;других
        коммуникационных службах сохраняется в&nbsp;базах данных (в&nbsp;том числе в&nbsp;публичном
        доступе) и&nbsp;не&nbsp;удаляется по&nbsp;требованию Пользователя. Полное удаление
        информации с&nbsp;сайта осуществляется в&nbsp;исключительных случаях и&nbsp;только
        по&nbsp;решению Администрации.
      </Paragraph>
      <Paragraph>
        4.12. Вы обязуетесь не использовать никакие искусственные методы по имитации, ускорению или
        изменению пользовательских действий на веб-сайте или в мобильных приложениях Hobius.
        Нарушение этого пункта ПС приведёт к немедленному удалению учётной записи без предупреждения
        и возможности восстановления.
      </Paragraph>

      <Title level={3}>5. Права и&nbsp;обязанности&nbsp;Администрации</Title>
      <Paragraph>
        5.1. Администрация имеет право размещать на&nbsp;странице с&nbsp;Вашим Содержанием рекламную
        информацию или любую другую информацию для публичного распространения, однако без ущерба для
        Содержания Вашей <Nobr>веб-страницы</Nobr>. Форма, порядок и&nbsp;объёмы такой информации
        могут время от&nbsp;времени изменяться&nbsp;Администрацией.
      </Paragraph>
      <Paragraph>
        5.2. Вы&nbsp;согласны с&nbsp;тем, что Администрация оставляет за&nbsp;собой право прекратить
        действие Вашего логина и&nbsp;пароля и&nbsp;удалить любое Содержание по&nbsp;любой причине,
        в&nbsp;том числе при неиспользовании доступа или при нарушении ПС. В&nbsp;частности, Ваша
        регистрация будет удалена, если Вы&nbsp;не&nbsp;пользовались им&nbsp;более 3&nbsp;месяцев
        с&nbsp;момента последнего визита. Администрация не&nbsp;несёт никакой ответственности
        за&nbsp;прекращение доступа к&nbsp;Hobius.&nbsp;
      </Paragraph>
      <Paragraph>
        5.3. Администрация обязуется предпринимать комплекс мер по&nbsp;защите регистрационных
        данных Пользователя от&nbsp;неправомерного доступа, уничтожения или изменения размещённой
        Пользователем информации, а&nbsp;также от&nbsp;иных неправомерных действий личной информации
        имеющимися в&nbsp;её распоряжении техническими&nbsp;средствами;
      </Paragraph>
      <Paragraph>
        5.4. Администрация имеет право предоставить всю имеющуюся информацию о&nbsp;Пользователе
        уполномоченным на&nbsp;то&nbsp;органам государственной власти в&nbsp;случаях,
        предусмотренных действующим законодательством&nbsp;РФ.
      </Paragraph>
      <Paragraph>
        5.5. Любое размещённое Пользователями Содержание может использоваться Администрацией для
        дальнейшего распространения как с&nbsp;указанием их&nbsp;автора, так и&nbsp;без этого, без
        необходимости получения специального разрешения Пользователя и&nbsp;без выплаты авторского
        вознаграждения.
      </Paragraph>

      <Title level={3}>6. Права&nbsp;собственности</Title>
      <Paragraph>
        6.1. Вы признаёте и&nbsp;соглашаетесь с&nbsp;тем, что интернет-ресурс Hobius и&nbsp;все
        необходимые программы, связанные с&nbsp;ним, содержат конфиденциальную информацию, которая
        защищена законами об&nbsp;интеллектуальной собственности и&nbsp;прочими российскими
        и&nbsp;международными законами, а&nbsp;Содержание, предоставляемое Вами в&nbsp;процессе
        пользования услугами, защищено авторскими правами, торговыми марками, патентами
        и&nbsp;прочими соответствующими законами. Кроме случаев, оговоренных специально
        с&nbsp;Администрацией, Вы&nbsp;соглашаетесь не&nbsp;модифицировать, не&nbsp;продавать,
        не&nbsp;распространять этот контент и&nbsp;программы, целиком либо по&nbsp;частям.
      </Paragraph>
      <Paragraph>
        6.2. Администрация предоставляет Вам личное неисключительное и&nbsp;непередаваемое право
        использовать программное обеспечение, предоставляемое в&nbsp;интернет-ресурсе Hobius,
        на&nbsp;одном компьютере, при условии, что ни&nbsp;Вы&nbsp;сами, ни&nbsp;любые иные лица при
        содействии с&nbsp;Вашей стороны не&nbsp;будут копировать или изменять программное
        обеспечение; создавать программы, производные от&nbsp;программного обеспечения; проникать
        в&nbsp;программное обеспечение с&nbsp;целью получения кодов программ; осуществлять продажу,
        уступку, сдачу в&nbsp;аренду, передачу третьим лицам в&nbsp;любой иной форме прав
        в&nbsp;отношении программного обеспечения служб, предоставленных Вам по&nbsp;ПС,
        а&nbsp;также модифицировать сервисы, в&nbsp;том числе с&nbsp;целью получения
        несанкционированного доступа к&nbsp;ним.&nbsp;
      </Paragraph>

      <Title level={3}>7. Освобождение от гарантий</Title>
      <Paragraph>7.1. Вы&nbsp;понимаете и&nbsp;соглашаетесь с&nbsp;тем, что:</Paragraph>
      <ul>
        <li>
          Вы&nbsp;используете интернет-ресурс Hobius на&nbsp;Ваш собственный риск. Услуги
          предоставляются «как есть». Администрация не&nbsp;принимает на&nbsp;себя никакой
          ответственности, в&nbsp;том числе и&nbsp;за&nbsp;соответствие услуг
          цели&nbsp;Пользователя;
        </li>
        <li>
          Администрация не&nbsp;гарантирует, что: услуги будут соответствовать Вашим требованиям;
          услуги будут предоставляться непрерывно, быстро, надёжно и&nbsp;без ошибок; результаты,
          которые могут быть получены с&nbsp;пользованием услуг, будут точными и&nbsp;надёжными;
          качество <Nobr>какого-либо</Nobr> продукта, услуги, информации и&nbsp;пр., полученного
          с&nbsp;использованием услуг, будет соответствовать Вашим ожиданиям; все ошибки
          в&nbsp;программах будут&nbsp;исправлены;
        </li>
        <li>
          любые материалы, полученные Вами с&nbsp;использованием Hobius, Вы&nbsp;можете использовать
          на&nbsp;свой собственный страх и&nbsp;риск, на&nbsp;Вас возлагается ответственность
          за&nbsp;любой ущерб, который может быть нанесен Вашему компьютеру и&nbsp;Вашим данным
          в&nbsp;результате загрузки этих&nbsp;материалов;
        </li>
        <li>
          Администрация не&nbsp;несёт ответственности за&nbsp;любые прямые или непрямые убытки,
          произошедшие <Nobr>из-за</Nobr>: пользования либо невозможности пользования услугами;
          несанкционированного доступа к&nbsp;Вашим коммуникациям; заявления или поведение любого
          третьего лица&nbsp;в&nbsp;службах;
        </li>
        <li>
          при любых обстоятельствах ответственность Администрации в&nbsp;соответствии
          со&nbsp;статьей&nbsp;15 Гражданского кодекса России ограничена 10&nbsp;000 (десятью
          тысячами) рублей и&nbsp;возлагается на&nbsp;неё при наличии в&nbsp;её действиях
          вины.&nbsp;
        </li>
      </ul>

      <Title level={3}>8. Общая информация</Title>
      <Paragraph>
        8.1. Ввиду безвозмездности услуг, оказываемых в&nbsp;рамках ПС, нормы о&nbsp;защите прав
        потребителей не&nbsp;могут быть к&nbsp;нему применимыми. В&nbsp;случае если ПС&nbsp;будет
        придан возмездный характер, в&nbsp;него будут внесены соответствующие изменения в&nbsp;целях
        соблюдения указанных норм.&nbsp;
      </Paragraph>
      <Paragraph>
        8.2. Ничто в&nbsp;ПС&nbsp;не&nbsp;может пониматься как установление между Вами
        и&nbsp;Администрацией агентских отношений, отношений товарищества, отношений
        по&nbsp;совместной деятельности, отношений личного найма, либо <Nobr>каких-то</Nobr> иных
        отношений, прямо не&nbsp;предусмотренных ПС.&nbsp;
      </Paragraph>
      <Paragraph>
        8.3. Признание судом какого-либо положения ПС&nbsp;недействительным или не&nbsp;подлежащим
        принудительному исполнению не&nbsp;влечёт недействительности или неисполнимости иных
        положений Соглашения.&nbsp;
      </Paragraph>
      <Paragraph>
        8.4. Бездействие со&nbsp;стороны Администрации в&nbsp;случае нарушения Вами либо иными
        пользователями положений ПС&nbsp;не&nbsp;лишает Администрацию права предпринять
        соответствующие действия в&nbsp;защиту своих интересов позднее, а&nbsp;также
        не&nbsp;означает отказа Администрации от&nbsp;своих прав в&nbsp;случае совершения
        в&nbsp;последующем подобных либо сходных&nbsp;нарушений.
      </Paragraph>
    </StaticPage>
  );
};

export default AgreementEn;
