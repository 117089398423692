import React from 'react';
import {Card, Button} from 'antd';

import Price from 'components/Price';
import Gallery from 'components/Gallery';
import Properties from 'components/Properties';
import Description from 'components/ProductDescription';

import Translate from './translation.json';
import {CardProps} from './types';
import './style.css';

const {Meta} = Card;

const ProductCard: React.FC<CardProps> = ({product}) => {
  const {name, price, description, extraInfoUrl, attachments} = product;
  const {images} = attachments;

  return (
    <article className='card'>
      <Card cover={<Gallery images={images} altText={name} />}>
        <Meta
          title={name}
          className='card-meta'
          description={<Description description={description} extraInfoUrl={extraInfoUrl} />}
        />
        <Price value={price} />

        <Properties {...product} />

        <Button type='primary' className='card-buybtn' block>
          {Translate.buyBtnLabel}
        </Button>
      </Card>
    </article>
  );
};

export default ProductCard;
export {default as CompactCard} from './Compact';
