import {bindActionCreators, Dispatch} from 'redux';
import {AppState} from 'store/types';
import {WithProductState, update, toggleTag} from 'store/currentProduct';
import {DispatchUpdate, DispatchToggleTag} from './types.js';

export const mapStateToProps = (state: AppState): WithProductState => ({
  currentProduct: state.currentProduct,
});

export const updateToProps = (dispatch: Dispatch): DispatchUpdate =>
  bindActionCreators({update}, dispatch);

export const toggleTagToProps = (dispatch: Dispatch): DispatchToggleTag =>
  bindActionCreators({toggleTag}, dispatch);
