import React from 'react';
import {Switch, Route} from 'react-router-dom';

import ScrollTop from 'components/ScrollTopOnRouteChange';
import Agreement from 'components/StaticPage/Agreement';
import Privacy from 'components/StaticPage/Privacy';

import AddProduct from 'views/AddProduct';
import Dashboard from 'views/Dashboard';
import NotFound from 'views/NotFound';
import Profile from 'views/Profile';
import Product from 'views/Product';
import Splash from 'views/Splash';

const AppRouter = () => {
  return (
    <>
      <ScrollTop />
      <Switch>
        <Route path='/profile'>
          <Profile />
        </Route>
        <Route path='/add'>
          <AddProduct />
        </Route>
        <Route path='/item/:id'>
          <Product />
        </Route>
        <Route path='/dashboard'>
          <Dashboard />
        </Route>
        <Route path='/' exact>
          <Splash />
        </Route>

        <Route path='/help/privacy/:locale'>
          <Privacy />
        </Route>
        <Route path='/help/tos/:locale'>
          <Agreement />
        </Route>

        <Route path='*'>
          <NotFound />
        </Route>
      </Switch>
    </>
  );
};

export default AppRouter;
