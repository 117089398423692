import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {Redirect} from 'react-router';

import {AppState} from 'store/types';
import {WithUserState, hasUser} from 'store/user';
import {WithNetworkState, isSavingProduct, savingProductError} from 'store/network';
import {State, saveProduct} from 'store/currentProduct';

import Loader from 'components/Loader';
import ErrorMessage from 'components/ErrorMessage';
import PageTitle from 'components/PageTitle';
import AddProductForm from './Form';
import Translate from './translation.json';
import './style.css';

interface DispatchFromProps {
  saveProduct(product: State): void;
}

type StoreProps = WithUserState & WithNetworkState;
type ComponentProps = StoreProps & DispatchFromProps;

const AddProduct: React.FC<ComponentProps> = ({user, network, saveProduct}) => {
  if (!hasUser(user)) {
    return <Redirect to='/' />;
  }

  const loading = isSavingProduct(network);
  const error = savingProductError(network);

  return (
    <section className='add-product'>
      <PageTitle>{Translate.page_title}</PageTitle>

      {loading && <Loader />}
      {error && <ErrorMessage>{error}</ErrorMessage>}

      <AddProductForm onSubmit={saveProduct} />
    </section>
  );
};

const mapStateToProps = (state: AppState): StoreProps => ({
  user: state.user,
  network: state.network,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps =>
  bindActionCreators({saveProduct}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddProduct);
