import {idOf} from 'store/utils';
import {AnyAction, AsyncActionId, AsyncType} from 'store/types';
import {State, NetworkProcess} from './types';
import {SAVE_PRODUCT} from 'store/currentProduct';

function createNetworkProcess(id: AsyncActionId): NetworkProcess {
  return {
    action: id,
    loading: true,
    error: null,
  };
}

function addProcess(type: AsyncType, state: State): State {
  const withNewProcess = [...state, createNetworkProcess(idOf(type))];
  return Array.from(new Set(withNewProcess));
}

function resolveProcess(type: AsyncType, state: State): State {
  return state.filter((process) => process.action !== idOf(type));
}

function rejectProcess(type: AsyncType, error: string, state: State): State {
  return state.map((process) => {
    const isDesired = process.action === idOf(type);
    return {
      ...process,
      error: isDesired ? error : null,
      loading: !isDesired,
    };
  });
}

const initialState: State = [];

export function network(state: State = initialState, {type, payload}: AnyAction): State {
  switch (type) {
    case SAVE_PRODUCT.REQUEST: {
      return addProcess(type, state);
    }

    case SAVE_PRODUCT.RESOLVE: {
      return resolveProcess(type, state);
    }

    case SAVE_PRODUCT.FAILURE: {
      const error = payload as string;
      return rejectProcess(type, error, state);
    }

    default:
      return state;
  }
}
