import React from 'react';
import {Icon, Radio} from 'antd';
import {Attachment} from './types';

interface AttachmentsProps {
  files: Attachment[];
  onDeleteFile: (file: Attachment) => void;
  onChageCover: (file: Attachment) => void;
}

const FileList: React.FC<AttachmentsProps> = ({files, onDeleteFile, onChageCover}) => (
  <div className='attach-files'>
    {files.map((attachment: Attachment) => {
      const {preview, name, progress, isCover} = attachment;

      return (
        <div className='attach-thumb' key={name}>
          {!!progress && <span className='attach-progress'>{progress}%</span>}

          <button className='attach-delete' type='button' onClick={() => onDeleteFile(attachment)}>
            <Icon type='delete' />
          </button>

          <Radio
            className='attach-is-cover'
            name='cover'
            checked={isCover}
            onChange={() => onChageCover(attachment)}
          />

          <img src={preview} alt='preview' />
        </div>
      );
    })}
  </div>
);

export default FileList;
