import React from 'react';
import {Link} from 'react-router-dom';
import {Typography} from 'antd';
import StaticPage from '..';
import Nobr from 'components/Nobr';

const {Title, Paragraph} = Typography;

const PrivacyRu = () => {
  return (
    <StaticPage title='Политика обработки персональных данных Изменить'>
      <Paragraph>
        Настоящая Политика обработки Персональных данных (далее&nbsp;— Политика) определяет общие
        принципы и&nbsp;порядок обработки Персональных данных Пользователей Сайта и&nbsp;меры
        по&nbsp;обеспечению их&nbsp;безопасности в&nbsp;Обществе с&nbsp;ограниченной
        ответственностью&nbsp;«Драйв».
      </Paragraph>

      <Title level={3}>Термины</Title>
      <Paragraph>
        <b>Автоматизированная обработка Персональных данных</b>&nbsp;— обработка Персональных данных
        с&nbsp;помощью средств вычислительной техники.
      </Paragraph>
      <Paragraph>
        <b>Данные</b>&nbsp;— данные о&nbsp;Пользователе, не&nbsp;входящие в&nbsp;понятие
        Персональных данных.
      </Paragraph>
      <Paragraph>
        <b>Законодательство</b>&nbsp;— действующее законодательство Российской Федерации.
      </Paragraph>
      <Paragraph>
        <b>Оператор</b>&nbsp;— <Nobr>ООО «Драйв»</Nobr>, самостоятельно или совместно с&nbsp;другими
        лицами организующее и&nbsp;(или) осуществляющее обработку Персональных данных, а&nbsp;также
        определяющее цели обработки Персональных данных, состав Персональных данных, подлежащих
        обработке, действия (операции), совершаемые с&nbsp;Персональными данными.
      </Paragraph>
      <Paragraph>
        <b>Персональные данные</b>&nbsp;— любая информация, относящаяся к&nbsp;прямо или косвенно
        определённому или определяемому физическому лицу (Пользователю).
      </Paragraph>
      <Paragraph>
        <b>Пользователь</b>&nbsp;— лицо, прошедшее процедуру регистрации на&nbsp;Сайте
        и&nbsp;имеющее доступ к&nbsp;Сайту посредством сети Интернет.
      </Paragraph>
      <Paragraph>
        <b>Предоставление Персональных данных</b>&nbsp;— действия, направленные на&nbsp;раскрытие
        Персональных данных определённому лицу или определённому кругу лиц.
      </Paragraph>
      <Paragraph>
        <b>Сайт</b>&nbsp;— сайт, принадлежащий Оператору и&nbsp;расположенный в&nbsp;сети Интернет
        по&nbsp;адресу <Link to='https://www.hobius.ru'>https://www.hobius.ru</Link>.
      </Paragraph>
      <Paragraph>
        <b>Субъект Персональных данных</b>&nbsp;— Пользователь (физическое лицо), к&nbsp;которому
        относятся Персональные данные.
      </Paragraph>
      <Paragraph>
        <b>Трансграничная передача Персональных данных</b>&nbsp;— передача Персональных данных
        на&nbsp;территорию иностранного государства органу власти иностранного государства,
        иностранному физическому или юридическому лицу.
      </Paragraph>

      <Title level={3}>1. Общие положения</Title>
      <Paragraph>
        1.1. Настоящая Политика в&nbsp;отношении обработки Персональных данных разработана
        в&nbsp;соответствии с&nbsp;положениями Федерального закона от&nbsp;<Nobr>27.07.2006</Nobr>{' '}
        №&nbsp;<Nobr>152-ФЗ</Nobr> «О&nbsp;персональных данных» (с&nbsp;изменениями
        и&nbsp;дополнениями), другими законодательными и&nbsp;нормативными правовыми актами
        и&nbsp;определяет порядок работы с&nbsp;Персональными данными Пользователей и&nbsp;(или)
        передаваемых Пользователями и&nbsp;требования к&nbsp;обеспечению их&nbsp;безопасности.
      </Paragraph>
      <Paragraph>
        1.2. Мероприятия по&nbsp;обеспечению безопасности Персональных данных являются составной
        частью деятельности Оператора.
      </Paragraph>

      <Title level={3}>2. Принципы обработки Персональных данных</Title>
      <Paragraph>
        2.1. Обработка Персональных данных Оператором осуществляется в&nbsp;соответствии
        со&nbsp;следующими принципами:
      </Paragraph>
      <Paragraph>
        2.1.1. Законность и&nbsp;справедливая основа обработки Персональных данных. Оператор
        принимает все необходимые меры по&nbsp;выполнению требований Законодательства,
        не&nbsp;обрабатывает Персональные данные в&nbsp;случаях, когда это не&nbsp;допускается
        Законодательством, не&nbsp;использует Персональные данные во&nbsp;вред Пользователю;
      </Paragraph>
      <Paragraph>
        2.1.2. Обработка только тех Персональных данных, которые отвечают заранее объявленным целям
        их&nbsp;обработки. Соответствие содержания и&nbsp;объёма обрабатываемых Персональных данных
        заявленным целям обработки. Недопущение обработки Персональных данных, несовместимых
        с&nbsp;целями сбора Персональных данных, а&nbsp;также избыточных по&nbsp;отношению
        к&nbsp;заявленным целям их&nbsp;обработки.
      </Paragraph>
      <Paragraph>
        Оператор обрабатывает Персональные данные исключительно в&nbsp;целях исполнения договорных
        обязательств перед Пользователем;
      </Paragraph>
      <Paragraph>
        2.1.3. Обеспечение точности, достаточности и&nbsp;актуальности Персональных данных
        по&nbsp;отношению к&nbsp;целям обработки Персональных данных. Оператор принимает все
        разумные меры по&nbsp;поддержке актуальности обрабатываемых Персональных данных, включая,
        но&nbsp;не&nbsp;ограничиваясь реализацией права каждого Субъекта получать для ознакомления
        свои Персональные данные и&nbsp;требовать от&nbsp;Оператора их&nbsp;уточнения, блокирования
        или уничтожения в&nbsp;случае, если Персональные данные являются неполными, устаревшими,
        неточными, незаконно полученными или не&nbsp;являются необходимыми для заявленных выше целей
        обработки;
      </Paragraph>
      <Paragraph>
        2.1.4. Хранение Персональных данных в&nbsp;форме, позволяющей определить Пользователя
        Персональных данных, не&nbsp;дольше, чем этого требуют цели обработки Персональных данных,
        если срок хранения Персональных данных не&nbsp;установлен Федеральным Законодательством,
        договором, стороной которого или выгодоприобретателем по&nbsp;которому является
        Пользователь;
      </Paragraph>
      <Paragraph>
        2.1.5. Недопустимость объединения созданных для несовместимых между собой целей баз данных
        Информационных систем Персональных данных.
      </Paragraph>

      <Title level={3}>3. Условия обработки персональных данных</Title>
      <Paragraph>
        3.1. Обработка Персональных данных Оператором осуществляется в&nbsp;следующих случаях:
      </Paragraph>
      {/* <Paragraph>
        3.1.1. При наличии согласия Пользователя на&nbsp;обработку его Персональных данных. Согласие
        даётся путём активация кнопки «Зарегистрироваться» при заполнении формы, расположенной на
        Сайте по адресу <Link to='https://www.hobius.ru/signup'>https://www.hobius.ru/signup</Link>;
      </Paragraph> */}
      <Paragraph>
        3.1.1. Персональные данные подлежат опубликованию или обязательному раскрытию
        в&nbsp;соответствии с&nbsp;Законодательством;
      </Paragraph>
      <Paragraph>
        3.2. Оператор не&nbsp;раскрывает третьим лицам и&nbsp;не&nbsp;распространяет Персональные
        данные без согласия Пользователя, если иное не&nbsp;предусмотрено Законодательством.
      </Paragraph>
      <Paragraph>
        3.3. Оператор не&nbsp;осуществляет Трансграничную передачу Персональных данных Пользователей
        в&nbsp;страны, не&nbsp;обеспечивающие адекватную защиту.
      </Paragraph>
      <Paragraph>
        3.4. Предоставляя Пользователям возможность использования Сайта, Оператор, действуя разумно
        и&nbsp;добросовестно, считает, что Пользователь:
      </Paragraph>
      <Paragraph>
        3.4.1 Обладает всеми необходимыми правами, позволяющими ему регистрироваться
        и&nbsp;авторизовываться на&nbsp;Сайте и&nbsp;использовать его;
      </Paragraph>
      <Paragraph>
        3.4.2. Осознает, что некоторые виды информации, переданные им&nbsp;другим Пользователям,
        не&nbsp;могут быть удалены самим Пользователем.
      </Paragraph>
      <Paragraph>
        3.5. Оператор не&nbsp;проверяет достоверность получаемой (собираемой) информации
        о&nbsp;Пользователях, за&nbsp;исключением случаев, когда такая проверка необходима
        в&nbsp;целях исполнения положений действующего применимого Законодательства и/или
        обязательств перед Пользователем.
      </Paragraph>

      <Title level={3}>4. Сбор и&nbsp;обработка персональных и&nbsp;иных данных</Title>
      <Paragraph>
        4.1. Оператор собирает и&nbsp;хранит только те&nbsp;Персональные данные, которые необходимы
        для предоставления услуг Пользователю, для осуществления рассылки материалов{' '}
        <Nobr>информационно-рекламного</Nobr> характера Пользователю посредством Сайта. При этом
        сбор Персональных данных может осуществляться как посредством Сайта, так и&nbsp;при обмене
        данными посредством электронной почты при заключении договора с&nbsp;Оператором
        в&nbsp;простой письменной форме.
      </Paragraph>
      <Paragraph>4.2. Оператор обрабатывает Персональные данные в&nbsp;целях:</Paragraph>
      <Paragraph>4.2.1. Предоставления доступа к&nbsp;Сайту;</Paragraph>
      <Paragraph>
        4.2.2. Оказания услуг Пользователю либо юридическому лицу (индивидуальному предпринимателю),
        представителем которого является Пользователь;
      </Paragraph>
      <Paragraph>
        4.2.3. Направления Пользователю рекламных и&nbsp;информационных рассылок посредством{' '}
        <Nobr>смс-уведомлений</Nobr> или электронной почты, предоставления бонусов и&nbsp;проведения
        стимулирующих мероприятий;
      </Paragraph>
      <Paragraph>
        4.2.4. Для связи с&nbsp;Пользователями в&nbsp;случае необходимости, в&nbsp;том числе для
        направления уведомлений, информации и&nbsp;запросов, связанных с&nbsp;оказанием услуг,
        а&nbsp;также обработки заявлений, запросов и&nbsp;заявок Пользователей;
      </Paragraph>
      <Paragraph>
        4.2.5. Для проведения статистических и&nbsp;иных исследований на&nbsp;основе обезличенных
        персональных данных.
      </Paragraph>
      <Paragraph>4.3. Оператор обрабатывает следующие Персональные данные:</Paragraph>
      <Paragraph>
        4.3.1. Персональные данные и&nbsp;Данные, которые вводятся Пользователем
        в&nbsp;Регистрационной форме при Регистрации:
      </Paragraph>
      <ul>
        <li>номер телефона;</li>
        <li>адрес электронной почты;</li>
        <li>фото;</li>
        <li>
          данные общедоступного профиля социальных сетей: ВКонтакте, Facebook, Одноклассники, Google
          и других.
        </li>
      </ul>
      <Paragraph>
        4.3.2. Персональные данные, вносимые в&nbsp;Личном кабинете на&nbsp;Сайте:
      </Paragraph>
      <ul>
        <li>фамилия, имя, отчество;</li>
        <li>пол;</li>
        <li>язык;</li>
        <li>данные о&nbsp;транспортном средстве (марка и&nbsp;модель);</li>
        <li>дата рождения;</li>
        <li>страна, город проживания;</li>
        <li>водительский стаж.</li>
      </ul>
      <Paragraph>
        4.3.3. Данные, дополнительно предоставляемые Пользователем по&nbsp;запросам Оператора,
        направляемым Пользователю в&nbsp;случае необходимости, например, с целью пресечения
        нарушения прав и&nbsp;законных интересов третьих лиц. Оператор вправе, в&nbsp;частности,
        запросить у&nbsp;Пользователя копию документа, удостоверяющего личность, либо иного
        документа, содержащего имя, фамилию, фотографию Пользователя, а&nbsp;также иную
        дополнительную информацию, которая, по&nbsp;усмотрению Оператора, будет являться необходимой
        и&nbsp;достаточной для идентификации такого Пользователя и&nbsp;позволит исключить
        злоупотребления и&nbsp;нарушения прав третьих лиц.
      </Paragraph>
      <Paragraph>
        4.4. В&nbsp;целях, указанных в&nbsp;пп. 4.2.1–4.2.4 Соглашения, Оператор обрабатывает
        следующую иную информацию/данные:
      </Paragraph>
      <Paragraph>
        4.4.1. Стандартные технические и&nbsp;иные данные, автоматически получаемые Оператором при
        доступе Пользователя к&nbsp;Сайту и&nbsp;последующих действиях Пользователя на&nbsp;Сайте
        (месторасположение в&nbsp;определенный момент времени, <Nobr>IP-адрес</Nobr>, вид
        операционной системы устройства Пользователя, разделы Сайта, посещаемые Пользователем);
      </Paragraph>
      <Paragraph>
        4.4.2. Информацию, полученную в&nbsp;результате действий Пользователя на&nbsp;Сайте;
      </Paragraph>
      <Paragraph>
        4.4.3. Информацию, полученную в&nbsp;результате действий других Пользователей на&nbsp;Сайте.
      </Paragraph>
      <Paragraph>
        4.5. В&nbsp;отношении Персональных данных и&nbsp;иных Данных Пользователя сохраняется
        их&nbsp;конфиденциальность, кроме случаев, когда указанные данные являются общедоступными.
      </Paragraph>
      <Paragraph>
        4.6. Оператор имеет право сохранять архивную копию Персональных данных и&nbsp;иных Данных,
        в&nbsp;том числе после удаления аккаунта Пользователя.
      </Paragraph>
      <Paragraph>
        4.7. Оператор имеет право передавать Персональные данные и&nbsp;иные Данные Пользователя без
        согласия Пользователя следующим лицам:
      </Paragraph>
      <Paragraph>
        4.7.1. Государственным органам, в&nbsp;том числе органам дознания и&nbsp;следствия, органам
        местного самоуправления по&nbsp;их&nbsp;мотивированному запросу;
      </Paragraph>
      <Paragraph>
        4.7.2. Партнерам Оператора c&nbsp;целью выполнения договорных обязательств перед
        Пользователем;
      </Paragraph>
      <Paragraph>
        4.7.3. В&nbsp;иных случаях, прямо предусмотренных действующим Законодательством&nbsp;РФ.
      </Paragraph>
      <Paragraph>
        4.8. Оператор имеет право передавать Персональные данные и&nbsp;иные Данные третьим лицам,
        не&nbsp;указанным в&nbsp;п. 4.7 настоящей Политики, в&nbsp;следующих случаях:
      </Paragraph>
      <Paragraph>4.8.1. Пользователь выразил свое согласие на&nbsp;такие действия.</Paragraph>
      <Paragraph>
        4.8.2. Передача необходима в&nbsp;рамках использования Пользователем Сайта.
      </Paragraph>
      <Paragraph>
        4.8.3. Передача происходит в&nbsp;рамках продажи или иной передачи бизнеса (полностью или
        в&nbsp;части), при этом к&nbsp;приобретателю переходят все обязательства по&nbsp;соблюдению
        условий настоящей Политики.
      </Paragraph>
      <Paragraph>
        4.9. Оператор осуществляет автоматизированную и&nbsp;неавтоматизированную обработку
        Персональных данных и&nbsp;иных Данных.
      </Paragraph>
      <Paragraph>
        4.10. Доступ к&nbsp;информационным системам, содержащим Персональные данные, обеспечивается
        системой паролей. Пароли устанавливаются уполномоченными сотрудниками Оператора
        и&nbsp;индивидуально сообщаются работникам Оператора, имеющим доступ к&nbsp;Персональным
        данным / Данным.
      </Paragraph>
      <Paragraph>
        4.11. При получении Оператором Персональных данных запроса, содержащего отзыв Субъекта
        Персональных данных согласия на&nbsp;обработку Персональных данных, Оператор в&nbsp;течение
        30 (тридцати) календарных дней с&nbsp;момента его получения обязан удалить Персональные
        данные.
        <br />
        Персональные данные Пользователя удаляются с&nbsp;Сайта при удалении Оператором информации,
        размещаемой Пользователем, а&nbsp;также Личного кабинета Пользователя по&nbsp;запросу
        Пользователя или по&nbsp;собственной инициативе в&nbsp;случаях, предусмотренных Лицензионным
        соглашением. После удаления Персональных данных Пользователя с&nbsp;Сайта Оператор хранит
        их&nbsp;на&nbsp;своих электронных носителях в&nbsp;течение установленного Законодательством
        Российской Федерации срока.
      </Paragraph>
      <Paragraph>
        4.12. Оператор не&nbsp;несет ответственности за&nbsp;разглашение Персональных данных
        Пользователя другими Пользователями Сайта и&nbsp;пользователями сети Интернет, получившими
        доступ к&nbsp;таким данным. Оператор рекомендует Пользователям ответственно подходить
        к&nbsp;решению вопроса об&nbsp;объеме информации о&nbsp;себе, размещаемой на&nbsp;Сайте.
      </Paragraph>

      <Title level={3}>5. Изменение персональных данных</Title>
      <Paragraph>
        5.1. Пользователь может в&nbsp;любой момент изменить (обновить, дополнить) Персональные
        данные, информацию о&nbsp;себе в&nbsp;своем Личном кабинете на&nbsp;Сайте, при условии, что
        такие изменения и&nbsp;исправления содержат актуальную и&nbsp;достоверную информацию,
        посредством направления письменного заявления Оператору или внесения изменений в&nbsp;своем
        Личном кабинете на&nbsp;Сайте.
      </Paragraph>
      <Paragraph>
        5.2. Пользователь в&nbsp;любой момент имеет право удалить Персональные данные / Данные.
      </Paragraph>

      <Title level={3}>6. Конфиденциальность персональных данных</Title>
      <Paragraph>
        6.1. Оператор обеспечивает конфиденциальность обрабатываемых им&nbsp;Персональных данных /
        Данных в&nbsp;порядке, предусмотренном Законодательством. Обеспечение конфиденциальности
        не&nbsp;требуется в&nbsp;отношении:
      </Paragraph>
      <Paragraph>6.1.1. Персональных данных после их&nbsp;обезличивания;</Paragraph>
      <Paragraph>
        6.1.2. Персональных данных, доступ неограниченного круга лиц к&nbsp;которым предоставлен
        Пользователем либо по&nbsp;его просьбе (далее&nbsp;— Персональные данные, сделанные
        общедоступными Пользователем);
      </Paragraph>
      <Paragraph>
        6.1.3. Персональных данных, подлежащих опубликованию или обязательному раскрытию
        в&nbsp;соответствии с&nbsp;Законодательством;
      </Paragraph>
      <Paragraph>
        6.2. Не&nbsp;является нарушением конфиденциальности Персональных данных предоставление
        Оператором информации третьим лицам, действующим на&nbsp;основании договора
        с&nbsp;Оператором для исполнения обязательств перед Пользователем.
      </Paragraph>

      <Title level={3}>7. Согласие пользователя на&nbsp;обработку персональных данных</Title>
      <Paragraph>
        7.1. Пользователь принимает решение о&nbsp;предоставлении своих Персональных данных
        Оператору и&nbsp;дает согласие на&nbsp;их&nbsp;обработку свободно, своей волей
        и&nbsp;в&nbsp;своем интересе. Согласие на&nbsp;обработку Персональных данных должно быть
        конкретным, информированным и&nbsp;сознательным и&nbsp;предоставляется Пользователем
        в&nbsp;момент его регистрации на&nbsp;Сайте Оператора либо в&nbsp;момент акцепта соглашения
        с&nbsp;Оператором, а&nbsp;также в&nbsp;любой позволяющей подтвердить факт его получения
        форме, если иное не&nbsp;установлено Законодательством.
      </Paragraph>
      <Paragraph>
        7.2. Персональные данные лиц, вступивших в&nbsp;договорные обязательства с&nbsp;Оператором,
        содержащиеся в&nbsp;единых государственных реестрах юридических лиц и&nbsp;индивидуальных
        предпринимателей, являются открытыми и&nbsp;общедоступными, за&nbsp;исключением сведений
        о&nbsp;номере, дате выдачи и&nbsp;органе, выдавшим документ, удостоверяющий личность
        физического лица. Охраны их&nbsp;конфиденциальности и&nbsp;согласия Пользователей
        на&nbsp;обработку не&nbsp;требуется.
      </Paragraph>
      <Paragraph>
        7.3. В&nbsp;случае поступления запросов из&nbsp;организаций, не&nbsp;обладающих
        соответствующими полномочиями, Оператор обязан получить предварительное согласие
        Пользователя на&nbsp;предоставление его Персональных данных и&nbsp;предупредить лиц,
        получающих Персональные данные, о&nbsp;том, что эти данные могут быть использованы лишь
        в&nbsp;целях, для которых они сообщены, а&nbsp;также требовать от&nbsp;этих лиц
        подтверждения того, что это правило будет (было) соблюдено.
      </Paragraph>

      <Title level={3}>8. Права субъекта персональных данных</Title>
      <Paragraph>
        8.1. Пользователь имеет право на&nbsp;получение информации, касающейся обработки его
        Персональных данных / Данных. Пользователь вправе требовать от&nbsp;Оператора уточнения его
        Персональных данных, их&nbsp;блокирования или уничтожения в&nbsp;случае, если Персональные
        данные являются неполными, устаревшими, неточными, незаконно полученными или
        не&nbsp;являются необходимыми для заявленной цели обработки, а&nbsp;также принимать
        предусмотренные законом меры по&nbsp;защите своих прав.
      </Paragraph>
      <Paragraph>
        8.2. Если Пользователь считает, что Оператор осуществляет обработку его Персональных данных
        с&nbsp;нарушением требований Законодательства или иным образом нарушает его права
        и&nbsp;свободы, Пользователь вправе обжаловать действия или бездействие Оператора
        в&nbsp;уполномоченном органе по&nbsp;защите прав Субъектов Персональных данных или
        в&nbsp;судебном порядке.
      </Paragraph>
      <Paragraph>
        8.3. Пользователь имеет право на&nbsp;защиту своих прав и&nbsp;законных интересов,
        в&nbsp;том числе на&nbsp;возмещение убытков и&nbsp;(или) компенсацию морального вреда
        в&nbsp;судебном порядке.
      </Paragraph>

      <Title level={3}>9. Иные положения</Title>
      <Paragraph>
        9.1. К&nbsp;настоящей Политике и&nbsp;отношениям между Пользователем и&nbsp;Оператором,
        возникающим в&nbsp;связи с&nbsp;применением Политики, подлежит применению право Российской
        Федерации.
      </Paragraph>
      <Paragraph>
        9.2. Все возможные споры подлежат разрешению в&nbsp;соответствии с&nbsp;Законодательством
        по&nbsp;месту регистрации Оператора. Перед обращением в&nbsp;суд Пользователь должен
        соблюсти обязательный досудебный порядок и&nbsp;направить Оператору соответствующую
        претензию в&nbsp;письменном виде. Срок ответа на&nbsp;претензию составляет 30 (тридцать)
        рабочих дней.
      </Paragraph>
      <Paragraph>
        9.3. Если по&nbsp;тем или иным причинам одно или несколько положений Политики будут признаны
        недействительными или не&nbsp;имеющими юридической силы, это не&nbsp;оказывает влияния
        на&nbsp;действительность или применимость остальных положений Политики.
      </Paragraph>
      <Paragraph>
        9.4. Оператор имеет право в&nbsp;любой момент изменять настоящую Политику (полностью или
        в&nbsp;части) в&nbsp;одностороннем порядке без предварительного согласования
        с&nbsp;Пользователем. Все изменения вступают в&nbsp;силу на&nbsp;следующий день после
        размещения на&nbsp;Сайте.
      </Paragraph>
      <Paragraph>
        9.5. Пользователь обязуется самостоятельно следить за&nbsp;изменениями Политики обработки
        персональных данных путем ознакомления с&nbsp;актуальной редакцией.
      </Paragraph>

      <Title level={3}>10. Контактная информация оператора</Title>
      <Paragraph>
        <Nobr>ООО «Драйв»</Nobr>
        <br />
        Тел.: <Nobr>+7 495 514-21-42</Nobr>
        <br />
        Адрес: 125315, г. Москва, Ленинградский проспект 72к3, 11 этаж
        <br />
        <Nobr>E-mail</Nobr>: <a href='mailto:support@hobius.ru'>support@hobius.ru</a>.
      </Paragraph>
    </StaticPage>
  );
};

export default PrivacyRu;
