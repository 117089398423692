import React from 'react';
import {Radio} from 'antd';
import {RadioChangeEvent} from 'antd/lib/radio';
import {Condition} from 'domain/product';
import Translate from './translation.json';

interface Props {
  onChange: (e: RadioChangeEvent) => void;
  value: Condition;
  name?: string;
}

const ConditionSelector: React.FC<Props> = ({value, onChange, name = 'condition'}) => {
  return (
    <Radio.Group name={name} value={value} onChange={onChange}>
      <Radio value={1}>{Translate.emergent}</Radio>
      <br />
      <Radio value={2}>{Translate.poor}</Radio>
      <br />
      <Radio value={3}>{Translate.good}</Radio>
      <br />
      <Radio value={4}>{Translate.perfect}</Radio>
      <br />
      <Radio value={5}>{Translate.original_packaging}</Radio>
    </Radio.Group>
  );
};

export default ConditionSelector;
