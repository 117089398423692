import React from 'react';
import {Link} from 'react-router-dom';
import {Breadcrumb} from 'antd';

import {sizeOf} from 'utils/sizeOf';
import './style.css';

interface Crumb {
  text: string;
  path?: string;
  current?: boolean;
}

interface Props {
  crumbs: Crumb[];
}

const Breadcrumbs: React.FC<Props> = ({crumbs}) => {
  if (sizeOf(crumbs) === 0) return null;

  return (
    <Breadcrumb className='breadcrumb'>
      {crumbs.map(({path, text, current}: Crumb) => {
        const Component = current ? 'span' : Link;

        return (
          <Breadcrumb.Item key={`${path}-${text}`}>
            <Component to={path as string}>{text}</Component>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
