import {AnyAction} from 'store/types';
import {UserRoles} from 'domain/user';
import {State} from './types';
import {SIGNOUT} from './actions';

const initialState = {
  id: 'testuser',
  name: 'bespoyasov',
  email: 'bespoyasov@me.com',
  roles: [UserRoles.Admin],
};

export function user(state: State = initialState, {type}: AnyAction): State {
  switch (type) {
    case SIGNOUT.REQUEST:
      return null;
    default:
      return state;
  }
}
