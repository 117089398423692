import React from 'react';
import {Alert} from 'antd';
import './style.css';

interface Props {
  children: React.ReactNode;
}

const ErrorMessage: React.FC<Props> = ({children}) => {
  return <Alert className='error-message' message={children} type='error' />;
};

export default ErrorMessage;
