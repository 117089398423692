import axios, {AxiosRequestConfig, AxiosInstance, ResponseType} from 'axios';
import {UrlString} from 'shared/types';

export type RequestConfig = AxiosRequestConfig;
export type Response = ResponseType;

interface RequestService {
  get<T = Response>(path: UrlString, config?: RequestConfig): Promise<T>;
  post<R = any, T = Response>(path: UrlString, data?: R, config?: RequestConfig): Promise<T>;
}

export class ApiClient implements RequestService {
  private instance: AxiosInstance;

  constructor(config?: RequestConfig) {
    this.instance = axios.create(config);
  }

  async get<T = Response>(path: UrlString, config?: RequestConfig): Promise<T> {
    return this.instance.get(path, config);
  }

  async post<R = any, T = Response>(path: UrlString, data?: R, config?: RequestConfig): Promise<T> {
    return this.instance.post(path, data, config);
  }
}
