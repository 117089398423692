import React from 'react';
import {Product} from 'domain/product';
import Translate from './translation.json';

type DescriptionProps = Pick<Product, 'description' | 'extraInfoUrl'>;

const Description: React.FC<DescriptionProps> = ({description, extraInfoUrl}) => (
  <div>
    {description}
    {' '}
    {!!extraInfoUrl && <a href={extraInfoUrl}>{Translate.moreInfo}</a>}
  </div>
);

export default Description;
