import React from 'react';
import Swiper from 'react-id-swiper';
import 'react-id-swiper/lib/styles/css/swiper.css';
import {ClientImage, galleryItem} from 'domain/image';
import {sizeOf} from 'utils/sizeOf';

interface Props {
  images: ClientImage[];
  altText: string;
}

const Gallery: React.FC<Props> = ({images, altText}) => {
  const swipable = sizeOf(images) > 1;
  const params = {
    noSwiping: !swipable,
    pagination: swipable && {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
  };

  return (
    <Swiper {...params}>
      {images.map((image: ClientImage) => (
        <div key={image.id}>
          <img src={galleryItem(image)} alt={altText} />
        </div>
      ))}
    </Swiper>
  );
};

export default Gallery;
