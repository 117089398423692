import {IndexSignature} from 'shared/types';

export type ErrorsIndex = IndexSignature<string>;
export type MessageIndex = IndexSignature<string>;

export interface ValidationResult {
  isValid: boolean;
  errors: ErrorsIndex;
}

export type ValidationRule = (value: string) => boolean;
export interface ValidationRules {
  [key: string]: ValidationRule[];
}

export type Validator = (payload: any) => ValidationResult;

export const mandatory: ValidationRule = (value: string): boolean => !!value;
export const numberLike: ValidationRule = (value: string): boolean => parseFloat(value) > 0;

export function createValidator(rules: ValidationRules, messages: MessageIndex): Validator {
  return function validate(payload: any): ValidationResult {
    let isValid = true;
    let errors: ErrorsIndex = {};

    Object.entries(rules).forEach(([field, rules]) => {
      const value = payload[field];
      const valid = rules.reduce((result, testRule) => result && testRule(value), true);

      if (!valid) {
        isValid = false;
        errors[field] = messages[field];
      }
    });

    return {isValid, errors};
  };
}
