import React from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {Redirect} from 'react-router';

import {Typography, Button} from 'antd';
import PageTitle from 'components/PageTitle';

import {AppState} from 'store/types';
import {WithUserState, hasUser, signout} from 'store/user';
import Translate from './translation.json';
import './style.css';

const {Text} = Typography;

interface DispatchFromProps {
  signout: () => void;
}

const Profile: React.FC<WithUserState & DispatchFromProps> = ({user, signout}) => {
  if (!hasUser(user)) {
    return <Redirect to='/' />;
  }

  return (
    <div className='profile'>
      <PageTitle>bespoyasov</PageTitle>
      <Text className='profile-text'>user@example.com</Text>

      <Button type='link' htmlType='button' onClick={signout}>
        {Translate.signout}
      </Button>
    </div>
  );
};

const mapStateToProps = (state: AppState): WithUserState => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps =>
  bindActionCreators({signout}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
