import {defaultCountry} from 'shared/countries';
import {Status, Product} from 'domain/product';
import {ClientImage} from 'domain/image';
import Sample1 from 'assets/books.jpg';
import Sample2 from 'assets/ticket1.jpg';
import Sample3 from 'assets/ticket2.jpg';
import Sample4 from 'assets/ticket3.jpg';

function makeGalleryItem(id: string, source: string): ClientImage {
  return {id, formats: {'gallery-item': source}};
}

export const products: Product[] = [
  {
    id: 'some-product-id',
    name: 'The Story of Hoover Dam',
    description: 'The full collection of “The Story of Hoover Dam”',
    stockNumber: 122,
    weight: 235,
    price: 1000,
    purchasePrice: 100,
    productionCountry: defaultCountry,
    productionYear: 1930,
    condition: 4,
    tags: [],
    attachments: {
      images: [makeGalleryItem('some-image-id', Sample1)],
    },

    createdAt: Date.now(),
    status: Status.ForSale,
  },
  {
    id: 'other-product-id',
    name: 'American Trust Company check',
    description: 'The ckeck for 29$ amount of American Trust Company, California.',
    stockNumber: 11,
    weight: 0.5,
    price: 400,
    purchasePrice: 100,
    productionCountry: defaultCountry,
    productionYear: 1929,
    condition: 5,
    tags: [],
    attachments: {
      images: [
        makeGalleryItem('other-image-id', Sample2),
        makeGalleryItem('yet-another-image-id', Sample3),
        makeGalleryItem('and-one-more-image-id', Sample4),
      ],
    },

    createdAt: Date.now(),
    status: Status.ForSale,
  },
];
