import {IndexSignature} from 'shared/types';

export function omit<T>(obj: IndexSignature<T>, fields: string[]): IndexSignature<T> {
  const shallowCopy = {...obj};

  for (const field of fields) {
    delete shallowCopy[field];
  }

  return shallowCopy;
}
