import React from 'react';
import PageTitle from 'components/PageTitle';

interface Props {
  title: string;
  children: React.ReactNode;
}

const StaticPage: React.FC<Props> = ({title, children}) => {
  return (
    <main>
      <PageTitle>{title}</PageTitle>
      {children}
    </main>
  );
};

export default StaticPage;
