import React from 'react';
import {Link} from 'react-router-dom';
import {Typography} from 'antd';
import StaticPage from '..';

const {Title, Paragraph} = Typography;

const AgreementEn = () => {
  return (
    <StaticPage title='User Agreement and Terms of Use'>
      <Title level={3}>Terms of Use</Title>
      <Paragraph>
        <Link to='/help/tos/ru'>По-русски</Link>
      </Paragraph>
      <Paragraph>
        These Terms of Use describe the terms and conditions under which you, the user, are granted
        the right to use Web sites operated by Drive.Net, Inc. ("Drive.Net") (hereinafter “Web site”
        if singular, or, "Web sites" if the use of more than one site is referred to).
      </Paragraph>
      <Title level={3}>USER AGREEMENT</Title>
      <Paragraph>
        BY USING THIS WEB SITE YOU REPRESENT YOU ARE OF LEGAL AGE AND AGREE TO BE BOUND BY THESE
        TERMS OF USE, AND ANY SUBSEQUENT MODIFICATIONS, AND THE PRIVACY POLICY AND ALL OTHER
        POLICIES POSTED ON THE WEB SITES.
      </Paragraph>
      <Paragraph>
        Your use of this Web site is contingent on the foregoing agreement, and if you do not agree,
        you must cease using the Web site. Your electronic acceptance of these Terms of Use and
        other posted policies through your use of this Web site shall have the same force and effect
        as if you had actually signed an agreement embodying these terms. DRIVE.NET may change these
        Terms of Use at any time by posting changes on this page of the Web site. It is your
        responsibility to review these Terms of Use prior to each use of the Web site. By continuing
        to use the Web site, you agree to any such changes.
      </Paragraph>
      <Title level={3}>WEB SITE AND CONTENT</Title>
      <Title level={3}>Web Site Availability</Title>
      <Paragraph>
        DRIVE.NET may change, suspend or discontinue availability of any aspect of the Web site at
        any time. We may also impose limits, registration requirements or fees on certain features
        or services or restrict your access to parts or the entire Web site or may delete or suspend
        any or your entire user created content without notice or liability. In addition, the Web
        site may contain links to other Internet sites, resources, and sponsors or advertisers on
        the Web site. We are not responsible for the availability of these outside resources, or
        their contents, and accordingly, you should direct any concerns regarding any link or other
        Web site to the site administrator or webmaster of such site.
      </Paragraph>
      <Title level={3}>Copyright</Title>
      <Paragraph>
        All content on the Web site is protected by copyright, and owned or controlled by DRIVE.NET,
        or the party accredited as the owner or provider of the content. You must abide by all
        copyright notices, information, or restrictions contained in any content accessed through
        the Web site.
      </Paragraph>
      <Title level={3}>Personal Non-Commercial Use</Title>
      <Paragraph>
        This Web site is for your personal and noncommercial use only. You may download or copy the
        content on this Web site for personal use, but only if you preserve and display all
        copyright and other notices relating to that content.
      </Paragraph>
      <Paragraph>
        Any other use of the content is prohibited without prior written permission from DRIVE.NET
        (see Contact Us at the end of these Terms of Use), or the copyright owner identified in the
        copyright notice in the content. Any commercial spam intended to drive traffic or otherwise
        promote your web site(s), goods or services using the Web Site or its blog or any other user
        created content feature of the Web site is strictly prohibited.
      </Paragraph>
      <Title level={3}>Links/Advertisements/Search Engines</Title>
      <Paragraph>
        The Web site may link to other sites operated by third parties. The inclusion of any link to
        such sites does not imply endorsement or recommendation by the Web site, or by DRIVE.NET of
        these sites, but is for your reference and convenience only. We are not responsible for the
        content or accuracy of any third-party or off-site pages or any other sites linked to the
        Web site. Linking to any other third party or off-site pages or other sites is at your own
        risk and subject to the terms and conditions of use of those sites.
      </Paragraph>
      <Paragraph>
        The Web site also contains advertisements by third parties. Unless otherwise specifically
        stated on the Web site, we do not endorse or make any representation or recommendation
        regarding the quality, or accuracy of any products or services featured in, or linked to any
        advertisement that appears on the Web site.
      </Paragraph>
      <Paragraph>
        Unless otherwise prohibited under these Terms of Use, you are permitted to create hyperlinks
        to the content on the Web site (including "deep linking" to articles or pages within site),
        provided that
      </Paragraph>
      <Paragraph>
        (1) the hyperlink text accurately describes the content as it appears on the Web site
      </Paragraph>
      <Paragraph>
        (2) no "scrapping" of the content by any means (i.e., extracting content from the Web site
        and reformatting it, aggregating it with other content or redistributing it other than in
        its complete originally displayed format)
      </Paragraph>
      <Paragraph>
        (3) under no circumstances may you "frame" the Web site or any of its content or copy
        portions of the Web site to a server, except as part of an Internet service provider's
        incidental caching of pages
      </Paragraph>
      <Paragraph>
        (4) each page within the Web site must be displayed in full (including all trademarks,
        branding, advertising and promotional materials), unaltered without any accompanying frame,
        border, margin, design, branding, trademark, advertising or promotional materials not
        originally displayed on the page within the Web site.
      </Paragraph>
      <Paragraph>
        We reserve the right at any time in our sole discretion to revoke this permission generally,
        or your right to use specific links. If you have any questions regarding these linking
        policies you may contact us, or if you wish to link in a manner not authorized in these
        Terms of Use, you must get our prior written permission (see Contact Information below).
      </Paragraph>
      <Paragraph>
        Search engines and indexing agents are required to obey the restrictions of any Robots.txt
        files located on DRIVE.NET Web sites.
      </Paragraph>
      <Title level={3}>Online Marketplace</Title>
      <Paragraph>
        Certain areas of the Web site are online marketplaces where registered users ("Buyers") may
        shop for goods listed and offered for sale ("Items") by authorized dealer/advertisers
        ("Sellers") who are commercial customers of DRIVE.NET and who are not affiliated with, or
        related in any way to, DRIVE.NET. DRIVE.NET intends to create a superior shopping
        experience, anchored by start-of-the-art technology and strong relationships with reputable
        Sellers.
      </Paragraph>
      <Paragraph>
        However, in our Online Marketplace(s), the Seller owns and manages the sales of Items on our
        Website. As such, ANY TRANSACTION ENTERED INTO BY A BUYER WITH A SELLER ON THE WEB SITE IS
        SOLELY BETWEEN THE BUYER AND THE SELLER and DRIVE.NET has no liability for the Item, its
        advertised description, pricing, fulfillment timing, packaging, shipping method, payment or
        any other characteristic or requirement of the transaction. While certain system-based
        features of the Web site support the processing and communications of these transactions,
        DRIVE.NET makes no representations or warranties regarding Marketplace transactions other
        than those described in the Legal Terms section below.
      </Paragraph>
      <Title level={3}>No Representations or Warranties</Title>
      <Paragraph>
        DRIVE.NET and its owned online operations make no representations or warranties as to the
        validity, truthfulness, accuracy or legality of any content on the Web site, including
        without limitation any content submitted by any user (see "Legal Terms" below).
      </Paragraph>
      <Title level={3}>USER CREATED CONTENT</Title>
      <Title level={3}>Submissions</Title>
      <Paragraph>
        You must be at least 18 years old to contribute content to this Web site.
      </Paragraph>
      <Paragraph>
        By submitting any material (text, photos, videos or other content) to DRIVE.NET either
        through the Web site or physical delivery, you agree to be bound by these Terms of Use. It
        is your responsibility to review and understand these terms at the time of your submission.
        By your submission you retain copyright ownership, but agree to the free and non-commercial
        redistribution and use of your content by other users, and agree to and grant the license to
        DRIVE.NET as described next.
      </Paragraph>
      <Paragraph>
        LICENSE: Through your submission of any materials of any kind (whether text, photos, video
        or other content), you grant to DRIVE.NET the irrevocable, nonexclusive, worldwide,
        royalty-free, fully paid-up, transferable and sub-licensable right and license to host,
        index, cache, tag, encode, use, copy, modify, adapt, transmit, excerpt, remove, publish,
        distribute, publicly display and perform, and create derivative works of, in each case in
        any media now or hereafter known and in any manner for any non-commercial or commercial
        purpose (collectively "Use") the content submitted by you with no monetary compensation at
        any time. By making any submission, you thereby waive your rights and allow the materials to
        be edited or otherwise changed in any manner not agreeable to you. DRIVE.NET has no
        obligation to provide attribution for any content you post. If you do not agree to these
        terms, do not submit any content. Nothing in these Terms of Use prohibits you from
        redistributing or selling your original content to other parties
      </Paragraph>
      <Paragraph>
        You are solely responsible for all content that you post, e-mail or otherwise make available
        through the Web site. For all content provided by you, you agree to indemnify DRIVE.NET as
        provided in Legal Terms below.
      </Paragraph>
      <Title level={3}>Content Submission Rules and Restrictions</Title>
      <Paragraph>By submitting any content, you represent and warrant that:</Paragraph>
      <Paragraph>
        (1) you either own the content or have all necessary rights to use and submit it;
      </Paragraph>
      <Paragraph>
        (2) all information you provide is true, accurate and complete, and does not violate these{' '}
        <strong>Terms of Use</strong> or the terms of any third party license;
      </Paragraph>
      <Paragraph>
        (3) the content will not cause injury, embarrassment or harm to any person or entity.
      </Paragraph>
      <Paragraph>
        You agree not to submit any content in any forums, chats, e-mails or otherwise that:
      </Paragraph>
      <Paragraph>
        (1) is libelous, defamatory, obscene, pornographic, or otherwise illegal;
      </Paragraph>
      <Paragraph>
        (2) is threatening, abusive or disruptive to other users (including without limitation
        repetitive, meaningless messages, personal attacks, content constituting spam, etc.);
      </Paragraph>
      <Paragraph>
        (3) abuses or discriminates on the basis of race, religion, nationality, gender, sexual
        orientation, age, disability, or other legally protected class;
      </Paragraph>
      <Paragraph>
        (4) infringes any party's intellectual property rights, including without limitation
        copyrights and trademarks;
      </Paragraph>
      <Paragraph>
        (5) impersonates another person, organization or misquotes another person;
      </Paragraph>
      <Paragraph>(6) violates any applicable law, or violates any party's rights;</Paragraph>
      <Paragraph>
        (7) offers, advertises or promotes any goods, services or web sites; or solicits any funds
        or consideration
      </Paragraph>
      <Paragraph>
        (8) contains malware or other virus insertions that may pop-up, install, attack or otherwise
        impact anyone using the Web site or any of its features.
      </Paragraph>
      <Paragraph>
        In addition, you agree not to contact any users of the Web site (including through off-line
        communication) for the purpose of threatening, harassing or abusing them due to their
        content submissions on the Web site.
      </Paragraph>
      <Paragraph>
        You agree that DRIVE.NET has no responsibility to store or provide use of your user created
        content. As such, DRIVE.NET disclaims liability for the, deletion of, failure to store,
        failure to provide access to, or failure to transmit any user created content placed on the
        Web site. DRIVE.NET retains the right to create limits on use and storage of user created
        content at its sole discretion and apply those limits at any time with or without notice.
      </Paragraph>
      <Paragraph>
        You are solely responsible for the content you submit. DRIVE.NET does not and cannot review
        every submission made and is not responsible for the content of any submissions. However,
        content that is deemed inappropriate or in violation of these Terms of Use, in DRIVE.NET's
        sole discretion, will be removed from public view and/or deleted in whole or in part. You
        may contact us if you disagree with any removal (see Contact Us below); however, all
        decisions regarding content and compliance with these Terms of Use will be determined by
        DRIVE.NET in its sole discretion. If you disagree with these conditions, do not submit any
        content.
      </Paragraph>
      <Title level={3}>Identity and Privacy</Title>
      <Paragraph>
        You must use a consistent handle or account name for any submissions you make, and you may
        not submit content under another person's name. If you are an elected official, you must
        submit content identifying yourself as such when posting about a political issue. "Sock
        puppets" are not permitted (i.e., using a fake or alternate identity to post content for a
        deceptive purpose, such as without limitation, praising a person or company without
        disclosing an underlying affiliation with that person or company).
      </Paragraph>
      <Paragraph>
        We reserve the right to include your real name on your internet postings if required through
        registration or otherwise provided to us, or when republishing your material in a printed
        edition of one of our publications.
      </Paragraph>
      <Paragraph>
        DRIVE.NET does not warrant or claim to have verified the identity of any user. There may be
        users who participate under a false identity.
      </Paragraph>
      <Paragraph>
        For information on our privacy policies regarding personal information gathered on the Web
        site, please see our Privacy Policy also located at the bottom of our Web pages, which
        policy is incorporated herein by reference.
      </Paragraph>
      <Title level={3}>Offline Meetings</Title>
      <Paragraph>
        DRIVE.NET Web sites may offer the ability for users to create personal profiles and
        otherwise offer blogs, message boards, or other similar forums that are designed to allow
        individuals to exchange information and ideas related to hobbies, collectibles and other
        special interest or enthusiast subject matters about which we choose to publish or otherwise
        develop electronic media. Such information exchanges enable individuals to meet each other
        for any purpose.
      </Paragraph>
      <Paragraph>
        In addition, any posting to the Web site will be publicly viewable, and your identity may be
        discovered by other users. We cannot vouch for the safety of offline meetings of individuals
        you meet through the Web site, and such meetings are held at your sole risk and liability.
        Furthermore, you are advised to be careful not to divulge personally identifying information
        about yourself, including but not limited to your telephone number(s), street addresses,
        URLs or e-mail addresses publicly on the Web site. If you do provide this information on the
        Web site or to a third party who you met through the Web site, it is at your sole risk.
      </Paragraph>
      <Paragraph>
        In addition, if you choose to submit content to the Web site in an area that requires that
        your real identity be disclosed, you do so at your sole risk and liability.
      </Paragraph>
      <Title level={3}>WEB SITE REGISTRATION</Title>
      <Title level={3}>Registrations</Title>
      <Paragraph>
        Some areas of the Web site may require registration in order to gain access or submit
        content. You may not create more than one account unless expressly permitted in the
        registration rules. If you create multiple accounts, you are prohibited from creating the
        appearance that multiple accounts controlled by you are in fact all different individuals.
      </Paragraph>
      <Title level={3}>Termination</Title>
      <Paragraph>
        You agree that at our sole discretion, and in our sole judgment, we may terminate your
        account at any time with or without cause.
      </Paragraph>
      <Paragraph>Termination for cause may include, but is not limited to:</Paragraph>
      <Paragraph>(1) Violations of these Terms of Use;</Paragraph>
      <Paragraph>(2) Violations of other posted rules of conduct;</Paragraph>
      <Paragraph>(3) Violations of law or intellectual property rights;</Paragraph>
      <Paragraph>
        (4) Harassment or belittling conduct toward individuals, companies or other third parties;
      </Paragraph>
      <Paragraph>
        (5) An expression of your unwillingness to abide by these terms and conditions or other
        clearly posted site rules;
      </Paragraph>
      <Paragraph>
        (1) (6) Failure to abide by the instructions for guidelines of forum moderators, editors or
        other authorized members of the community to provide such moderation;
      </Paragraph>
      <Paragraph>
        (6) Failure to provide real, verifiable information about your true identity where requested
        or instructed.
      </Paragraph>
      <Paragraph>
        <u>Termination of a user's account may include any of the following actions:</u>
      </Paragraph>
      <Paragraph>
        A complete deletion (non-recoverable) from all files, databases and any and all storage
        mechanism of the user's files, uploads, content, and personally identifiable information. In
        such a case, neither DRIVE.NET nor its third party service providers have any obligation to
        retrieve or restore deleted data, or otherwise make good in any fashion the loss of such
        data.
      </Paragraph>
      <Paragraph>
        User data, including without limitation submitted content, errant code or personal
        information, may also be hidden from public view, but not destroyed. In such a case, neither
        DRIVE.NET nor any third party service providers are under any obligation to retrieve or
        restore such data.
      </Paragraph>
      <Paragraph>
        Users who are terminated are prohibited from creating new accounts without prior written
        consent of DRIVE.NET. DRIVE.NET will determine in its sole discretion whether to reinstate a
        given User. Users may also appeal account termination by writing to: help@hobius.com
      </Paragraph>
      <Paragraph>
        When applying for a new account, or appealing a termination decision, please include
        printouts of all relevant data, e-mails, postings, including the domain name where the
        primary account was held and the date the account was terminated. Once your termination is
        under review, all subsequent communication will be through e-mail, so please be sure to
        include a current and working e-mail address with your letter. You acknowledge and agree
        that DRIVE.NET will make the final determination in its sole discretion as to any terminated
        account, or re-application for a new account after termination and that this represents your
        sole and exclusive remedy for any terminated account.
      </Paragraph>
      <Title level={3}>LEGAL TERMS</Title>
      <Paragraph>
        <strong>
          <u>No Representations or Warranties as to Web site</u>
        </strong>
        <strong>:</strong>
      </Paragraph>
      <Paragraph>
        <strong>
          The web site and all content provided by DRIVE.NET or its third party service providers is
          distributed and accessible on an AS IS and AS AVAILABLE basis. DRIVE.NET and its third
          party service providers make no representations or warranties that operation of the web
          site (or services obtained through the web site) will be uninterrupted or error free and
          do not provide any warranty as to the use, sufficiency or accuracy of any content. All
          warranties, conditions, representations, indemnities and guarantees as to products and
          services provided by DRIVE.NET, whether express or implied, arising by law, custom, prior
          oral or written statements by DRIVE.NET, its third party service providers or otherwise
          (including, but not limited to, any implied warranty of merchantability, fitness for
          particular purpose, satisfactory quality, title or non-infringement) are hereby
          overridden, excluded and disclaimed.
        </strong>
      </Paragraph>
      <Paragraph>
        <strong>
          <u>Limitation of Liability</u>
        </strong>
        <strong>:</strong>
      </Paragraph>
      <Paragraph>
        <strong>
          Under no circumstances will DRIVE.NET (or its directors, employees or agents) be liable to
          you or any third person for any for any consequential, indirect, special, punitive or
          incidental damages, or lost profits or lost data, whether foreseeable or unforeseeable,
          arising from your use of its Web sites, any applications available through its Web sites,
          or any Web site content or other materials on, accessed through or downloaded from its Web
          sites. In addition, and notwithstanding anything to the contrary contained herein, the
          DRIVE.NET liability to you for any cause whatsoever, and regardless of the form of the
          action, will at all times be limited to the amount paid, if any, by you for Web site
          products or services accessed by you, but in no case will such liability to you exceed
          $100. You acknowledge that if no fees are paid by you for the Web sites, you shall be
          limited to injunctive relief only, unless otherwise permitted by law, and shall not be
          entitled to damages of any kind from DRIVE.NET, regardless of the cause of action. Certain
          state laws do not allow limitations on implied warranties or the exclusion or limitation
          of certain damages. If these laws apply to you, some or all of the above disclaimers,
          exclusions or limitations may not apply to you, and you may have additional rights.
        </strong>
      </Paragraph>
      <Title level={3}>Indemnity</Title>
      <Paragraph>
        You agree at your expense to indemnify and hold harmless, DRIVE.NET and each of their
        respective partners, suppliers, licensors, officers, directors, shareholders, employees,
        representatives, contractors and agents, from any and all claims, suits, actions and
        investigations, and any costs, expenses, liabilities or damages therefrom (including
        attorneys' fees and court costs) arising from or relating to any allegation regarding:
      </Paragraph>
      <Paragraph>(1) your use of the Web site;</Paragraph>
      <Paragraph>
        (2) the Web site's or DRIVE.NET's use of any content or information you provide, as long as
        our use is not inconsistent with these Terms of Use;
      </Paragraph>
      <Paragraph>
        (3) information or material posted or transmitted through your registration account or user
        ID, even if not posted by you;
      </Paragraph>
      <Paragraph>(4) any violation of these Terms of Use by you (collectively "Claims").</Paragraph>
      <Paragraph>
        DRIVE.NET reserves the right to select counsel and conduct the defense or settlement of any
        such Claim, which shall not relieve you of your obligation to indemnify as provided above.
      </Paragraph>
      <Title level={3}>Governing Law and Venue</Title>
      <Paragraph>
        The agreement regarding these Terms of Use has been made in and shall be construed and
        enforced in accordance with California law, without regard to its principles of the
        conflicts of law. Any action to enforce this agreement shall be brought in the federal or
        state courts located or having jurisdiction in Santa Clara County, State of California, and
        you hereby waive any objections to such action. Any cause of action you may have with
        respect to the Web site or these Terms of Use must be commenced within one year after the
        claim or cause of action arises or such claim or cause of action is barred.
      </Paragraph>
      <Title level={3}>Severability; Waiver</Title>
      <Paragraph>
        If any provision of these Terms of Use is found to be invalid by any court having competent
        jurisdiction, the invalidity of such provision shall not affect the validity of the
        remaining provisions of these Terms of Use, which shall remain in full force and effect. No
        waiver of these Terms of Use shall be effective unless the waiver is in a writing signed by
        an authorized representative of DRIVE.NET. No waiver shall be deemed a further or continuing
        waiver of such term or condition or any other term or condition.
      </Paragraph>
      <Title level={3}>Copyright Violations</Title>
      <Paragraph>
        Users are prohibited from uploading, posting or otherwise transmitting on the Web site any
        materials that violate another party's intellectual property rights. When we receive proper
        notification of alleged copyright infringement, we promptly remove or disable access to the
        allegedly infringing material and terminate the accounts of repeat infringers in accordance
        with the Digital Millennium Copyright Act.
      </Paragraph>
      <Paragraph>
        If you believe that any material on the Web site infringes upon any copyright which you own
        or control, please notify us at help@hobius.com (Please direct all general questions to
        help@hobius.com)
      </Paragraph>
      <Title level={3}>CONTACT INFORMATION</Title>
      <Paragraph>
        If you have any questions about these Terms of Use, please contact us in one of the
        following ways:
      </Paragraph>
      <Paragraph>
        E-mail us at: <a href='mailto:help@hobius.com?subject=Re: Terms of Use'>help@hobius.com</a>{' '}
        and insert only the words "Re: Terms of Use" in the subject line of the e-mail.
      </Paragraph>
    </StaticPage>
  );
};

export default AgreementEn;
