import React from 'react';
import Dropzone from 'react-dropzone';
import {Button} from 'antd';

import {uploadApiInvoker} from 'interactors/invokeApi';
import {Attachment} from './types';
import FileList from './FileList';
import Translate from './translation.json';
import './style.css';

function attachmentFromFile(file: File): Attachment {
  return {
    name: file.name,
    preview: URL.createObjectURL(file),
    progress: 0,
    isCover: false,
  };
}

function attachmentToId({name}: Attachment): string {
  return name;
}

interface OwnProps {
  onChange(fileIds: string[]): void;
}

interface State {
  files: Attachment[];
}

export default class Attach extends React.Component<OwnProps, State> {
  state: State = {
    files: [],
  };

  private findFileIndexByName = (fileName: string): number => {
    return this.state.files.findIndex((f: any) => f.name === fileName);
  };

  private callOnChange = () => {
    const {onChange} = this.props;
    const {files} = this.state;
    return onChange(files.map(attachmentToId));
  };

  private onUploadProgress = (fileName: string, progress: ProgressEvent) => {
    const completed = Math.round((progress.loaded * 100) / progress.total);
    const index = this.findFileIndexByName(fileName);
    const copy = [...this.state.files];

    copy[index].progress = completed;
    this.setState({files: copy});
  };

  private onDrop = (acceptedFiles: File[]) => {
    this.setState({
      files: [...this.state.files, ...acceptedFiles.map(attachmentFromFile)],
    });

    acceptedFiles.forEach(async (file: File) => {
      const fileName = file.name;
      const formData = new FormData();
      formData.append('file', file);

      await uploadApiInvoker.upload(formData, {
        onUploadProgress: this.onUploadProgress.bind(null, fileName),
      });

      this.callOnChange();
    });
  };

  private deleteAttachment = ({name: deletedName}: Attachment) => {
    const filesCopy = this.state.files.filter(({name}) => name !== deletedName);
    this.setState({files: filesCopy});
  };

  private changeCover = ({name: updatedName}: Attachment) => {
    const filesCopy = this.state.files.map((file) => ({
      ...file,
      isCover: updatedName === file.name,
    }));

    this.setState({files: filesCopy});
  };

  private cleanupMemory = () => {
    this.state.files.forEach((file) => URL.revokeObjectURL(file.preview));
  };

  componentWillUnmount() {
    this.cleanupMemory();
  }

  render() {
    return (
      <section>
        <FileList
          files={this.state.files}
          onDeleteFile={this.deleteAttachment}
          onChageCover={this.changeCover}
        />

        <Dropzone onDrop={this.onDrop} accept='image/*'>
          {({getRootProps, getInputProps}) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Button type='dashed' tabIndex={-1} block>
                {Translate.upload}
              </Button>
            </div>
          )}
        </Dropzone>
      </section>
    );
  }
}
