import React from 'react';
import {Typography} from 'antd';

const {Title} = Typography;

interface Props {
  children: React.ReactNode;
}

const PageTitle: React.FC<Props> = ({children}: Props) => {
  return <Title level={2}>{children}</Title>;
};

export default PageTitle;
