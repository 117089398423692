import React from 'react';
import {Link} from 'react-router-dom';
import {Card} from 'antd';

import Price from 'components/Price';
import {galleryItem} from 'domain/image';
import {exists} from 'utils/exists';
import {CardProps} from './types';
import './style.css';

const {Meta} = Card;

const Compact: React.FC<CardProps> = ({product}) => {
  const {id, price, name, description, productionYear, attachments} = product;
  const title = [name, productionYear].filter(exists).join(', ');
  const cover = galleryItem(attachments.images[0]);

  return (
    <Link to={`/item/${id}`}>
      <article className='card'>
        <Card cover={<img src={cover} alt={name} />}>
          <Meta title={title} description={description} className='card-meta' />
          <Price value={price} />
        </Card>
      </article>
    </Link>
  );
};

export default Compact;
