import React from 'react';
import {PriceCents} from 'domain/product';
import {formatPrice} from 'utils/formatPrice';
import './style.css';

interface Props {
  value: PriceCents;
}

const Price: React.FC<Props> = ({value}) => {
  return <span className='price'>{formatPrice(value)}</span>;
};

export default Price;
