import React from 'react';
import {Product} from 'domain/product';
import {conditionToText} from 'adapters/conditionToText';
import {formatNumber} from 'utils/formatNumber';
import Translate from './translation.json';
import './style.css';

type PropUnion = 'condition' | 'productionCountry' | 'brand' | 'productionYear' | 'weight';
type PropertiesType = Pick<Product, PropUnion>;

const Properties: React.FC<PropertiesType> = ({
  condition,
  productionCountry,
  brand,
  productionYear,
  weight,
}) => {
  return (
    <ul className='properties'>
      <li>
        <span>{Translate.condition}</span>
        <span>{conditionToText(condition)}</span>
      </li>

      {!!productionCountry && (
        <li>
          <span>{Translate.country}</span>
          <span>{productionCountry.name}</span>
        </li>
      )}

      {!!brand && (
        <li>
          <span>{Translate.brand}</span>
          <span>{brand}</span>
        </li>
      )}

      <li>
        <span>{Translate.year}</span>
        <span>{productionYear}</span>
      </li>

      <li>
        <span>{Translate.weight}</span>
        <span>{formatNumber(weight)}</span>
      </li>
    </ul>
  );
};

export default Properties;
