import React from 'react';
import {useParams} from 'react-router-dom';
import AgreementEn from './En';
import AgreementRu from './Ru';

const Agreement = () => {
  const {locale} = useParams();
  return locale === 'ru' ? <AgreementRu /> : <AgreementEn />;
};

export default Agreement;
