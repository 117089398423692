import {Tag} from 'domain/product';
import {SyncType, AsyncType} from 'store/types';
import {createType, createAction, createAsyncTypes} from 'store/utils';
import {StateIndex, UpdatePayload, State} from './types';

const prefix = 'currentProduct';

export const UPDATE = createType(prefix, 'UPDATE');
export const update = (field: StateIndex, value: string) =>
  createAction<SyncType, UpdatePayload>(UPDATE, {field, value});

export const TOGGLE_TAG = createType(prefix, 'TOGGLE_TAG');
export const toggleTag = (tag: Tag) => createAction<SyncType, Tag>(TOGGLE_TAG, tag);

export const SAVE_PRODUCT = createAsyncTypes(prefix, 'SAVE_PRODUCT');
export const saveProduct = (product: State) =>
  createAction<AsyncType, State>(SAVE_PRODUCT.REQUEST, product);
