import {State} from './types';
import {User, UserRoles} from 'domain/user';

export function hasUser(state: State): boolean {
  return !!state;
}

export function isAdmin(state: State): boolean {
  if (!hasUser(state)) return false;
  return (state as User).roles.includes(UserRoles.Admin);
}
