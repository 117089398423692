import {AsyncActionId} from 'store/types';
import {State, NetworkProcess} from './types';
import {SAVE_PRODUCT} from 'store/currentProduct';

function processById(id: AsyncActionId, state: State): NetworkProcess | undefined {
  return state.find((process) => process.action === id);
}

function isLoading(id: AsyncActionId) {
  return function(state: State): boolean {
    const process = processById(id, state);
    return !!process && process.loading;
  };
}

function errorOf(id: AsyncActionId) {
  return function(state: State): string | null {
    const process = processById(id, state);
    return !!process ? process.error : null;
  };
}

export const isSavingProduct = isLoading(SAVE_PRODUCT.id);
export const savingProductError = errorOf(SAVE_PRODUCT.id);
