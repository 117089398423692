import React from 'react';
import {useParams} from 'react-router-dom';
import PrivacyEn from './En';
import PrivacyRu from './Ru';

const Privacy = () => {
  const {locale} = useParams();
  return locale === 'ru' ? <PrivacyRu /> : <PrivacyEn />;
};

export default Privacy;
