interface FormatterOptions {
  locale?: string;
  options?: Intl.NumberFormatOptions;
}

export function formatNumber(
  amount: number,
  {options, locale = 'ru-RU'}: FormatterOptions = {}
): string {
  const formatter = new Intl.NumberFormat(locale, options);
  return formatter.format(amount);
}
