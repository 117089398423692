import React from 'react';
import {Router} from 'react-router-dom';

import {Provider} from 'react-redux';
import history from 'store/history';
import store from 'store';

import {Layout} from 'antd';
import AppRouter from './Router';
import Header from 'components/Header';
import './style.css';

const {Content} = Layout;

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Layout className='app'>
          <Header />

          <Content className='app-main'>
            <AppRouter />
          </Content>
        </Layout>
      </Router>
    </Provider>
  );
};

export default App;
