import {Action, SyncType, AsyncType, AsyncActionId, AsyncActionTypes} from './types';

export function createType(prefix: string, type: string): SyncType {
  return `${prefix}/${type}`;
}

export function createAsyncTypes(prefix: string, type: string): AsyncActionTypes {
  const base = createType(prefix, type);
  return {
    id: base,
    REQUEST: `${base}-REQUEST`,
    RESOLVE: `${base}-RESOLVE`,
    FAILURE: `${base}-FAILURE`,
  };
}

export function idOf(type: AsyncType): AsyncActionId {
  return type.substr(0, type.indexOf('-'));
}

export function createAction<A, P>(type: A, payload?: P): Action<A, P> {
  return {type, payload};
}
