import countries from 'i18n-iso-countries';
import ru from 'i18n-iso-countries/langs/ru.json';
import {Country} from 'domain/country';

export const defaultCountry: Country = {
  name: 'США',
  code: 'US',
};

countries.registerLocale(ru);

export const countriesList = Object.entries(countries.getNames('ru')).map(([key, value]) => ({
  name: value,
  code: key,
}));
