import {takeEvery} from 'redux-saga/effects';
import {productApiInvoker, callApi} from 'interactors/invokeApi';
import history from 'store/history';
import {SAVE_PRODUCT, saveProduct as action} from '../actions';

function* saveProduct({payload}: ReturnType<typeof action>) {
  const succeded = yield callApi(productApiInvoker.save, SAVE_PRODUCT, payload);
  if (succeded) history.push('/');
}

export function* watchSaveProduct() {
  yield takeEvery(SAVE_PRODUCT.REQUEST, saveProduct);
}
