import {createValidator, mandatory, numberLike, ValidationRules} from 'interactors/validation';
import {validation} from './translation.json';

const validationRules: ValidationRules = {
  name: [mandatory],
  description: [mandatory],
  stockNumber: [mandatory],
  weight: [mandatory, numberLike],
  condition: [mandatory],
  price: [mandatory, numberLike],
  purchasePrice: [mandatory, numberLike],
};

export const validate = createValidator(validationRules, validation);
