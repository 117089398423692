export default [
  '18 век',
  '19 век',
  '1900-е',
  '1910-е',
  '20-е',
  '30-е',
  '40-е',
  '50-е',
  '60-е',
  '70-е',
  '80-е',
  '90-е',
  '2000-е',
  '2010-е',
];
