import {takeEvery} from 'redux-saga/effects';
import {userApiInvoker, callApi} from 'interactors/invokeApi';
import {SIGNOUT} from 'store/user';

function* signout() {
  yield callApi(userApiInvoker.signout, SIGNOUT);
}

export function* watchSignout() {
  yield takeEvery(SIGNOUT.REQUEST, signout);
}
