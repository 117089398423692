import React from 'react';
import {Tag as AntdTag} from 'antd';
import {injectIf} from 'utils/injectIf';
import './style.css';

interface Props {
  children: React.ReactNode;
  checkable?: boolean;
  checked?: boolean;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Tag: React.FC<Props> = ({children, checkable, checked, onChange, name}) => {
  const Wrapper = checkable ? 'label' : 'span';

  return (
    <Wrapper className='tag'>
      {checkable && (
        <input
          className='visually-hidden'
          type='checkbox'
          name={name}
          checked={checked}
          onChange={onChange}
        />
      )}

      <AntdTag color={injectIf(!!checked, '#108ee9')}>{children}</AntdTag>
    </Wrapper>
  );
};

export default Tag;
