import React from 'react';
import {useParams} from 'react-router-dom';

import NotFound from 'views/NotFound';
import ProductCard from 'components/ProductCard';
import Breadcrumbs from 'components/Breadcrumbs';

import {Product as ProductType} from 'domain/product';
import {products} from 'domain/stubs';

const Product = () => {
  const {id} = useParams();
  const product = products.find(({id: productId}: ProductType) => productId === id);

  if (!product) return <NotFound />;

  return (
    <div>
      <Breadcrumbs
        crumbs={[{path: '/', text: 'All Products'}, {text: product.name, current: true}]}
      />

      <ProductCard product={product} />
    </div>
  );
};

export default Product;
