import React from 'react';
import {Link} from 'react-router-dom';
import {Typography} from 'antd';
import StaticPage from '..';

const {Title, Paragraph} = Typography;

const Privacy = () => {
  return (
    <StaticPage title='Privacy Policy and Data Use'>
      <Title level={3}>Privacy Policy</Title>
      <Paragraph>
        <Link to='/help/privacy/ru'>По-русски</Link>
      </Paragraph>
      <Paragraph>
        This privacy policy describes the privacy practices for the Web sites operated by Drive.Net,
        Inc. («DRIVE.NET») (hereinafter «Web site» if singular, or, «Web sites» if more than one
        DRIVE.NET site is referred to).
      </Paragraph>
      <Title level={3}>1. INFORMATION COLLECTION AND USE</Title>
      <Paragraph>We gather two types of information:</Paragraph>
      <ul>
        <li>
          (1) personally identifiable information that you supply voluntarily when you register for
          interactive services, order other products or services, or enter certain other areas of
          our Web sites as noted below in this Privacy Policy
        </li>
        <li>
          (2) aggregate tracking and site usage information that we gather automatically as you
          access our Web sites.
        </li>
      </ul>
      <Paragraph>
        Your provision to us of any information through our Web sites, or through other sites that
        link to our Web sites, is deemed your agreement to this Privacy Policy. It is our policy to
        use the personally identifiable information we acquire at our Web sites for internal
        business uses only and to provide content, products and services to you that you value and
        request.
      </Paragraph>
      <Paragraph>
        We do not authorize the use of your personal information by any third party (other than
        DRIVE.NET business services providers discussed in the next section or, under exceptional
        conditions as described under «Legal Matters» below). We operate and maintain a variety of
        online security measures to safeguard and keep your information private.
      </Paragraph>
      <Title level={3}>Personally Identifiable Information You Provide to Us</Title>
      <Paragraph>
        Anyone can access our Web sites, but there are certain areas and services that require
        registration or provision of certain personal information if accessed, such as by way of
        example: entering premium content areas, placing advertisements online and making other
        online purchases, signing up for e-mail newsletters, promotions, contests, sweepstakes or
        special offers, or participating in reader forums, message boards, chats and reader surveys.
      </Paragraph>
      <Paragraph>
        If you choose to make a purchase or use such areas, we will ask for certain personally
        identifiable information, which may include name, mailing address, valid e-mail address and
        other personal information. Based upon the personally identifiable information you provide
        us at registration, we may send you a welcoming e-mail to verify your username and password.
        We will also communicate with you in response to your inquiries, regarding any information
        or services you request, and to manage your «account». We will communicate with you by
        e-mail or other method as directed by you. If you decline to supply or maintain with us
        certain information while using our Web sites, understand that you may not be able to use or
        participate in some or all of the features offered through the Web sites.
      </Paragraph>
      <Paragraph>
        We strive to keep all personal information (such as your name or e-mail address) that you
        voluntarily provide us private. We do not authorize your personal information to be shared
        with third parties without your consent. Certain information supplied by you to our Web
        sites may be password-protected, and you should take all necessary measures to protect the
        secrecy of your password. If you use a computer in a public place or share a computer with
        others, remember to log out/sign out and close your browser window when you finish accessing
        our Web site in order to prevent others from accessing your personal information. You are
        solely responsible for the control and use of each password you create.
      </Paragraph>
      <Paragraph>
        Personal information which you provide on user forums or bulletin boards or in other areas
        that are accessible to other users could also be collected and disclosed by other users. We
        cannot and do not take any responsibility for such collection or disclosure.
      </Paragraph>
      <Paragraph>
        From time to time, we may obtain e-mail addresses from third party commercial sources to be
        used for promotional purposes. You may opt out of receiving any messages from us in the
        future by either clicking the appropriate box, radio button or link in the e-mail message
        received, or by contacting us as instructed at the end of this Privacy Policy.
      </Paragraph>
      <Title level={3}>Aggregate Information that is Automatically Collected</Title>
      <Paragraph>
        We also collect and store information that is generated automatically as you navigate
        through our Web sites.
      </Paragraph>
      <Paragraph>
        For example, to enhance your experience on our Web sites, we use a standard feature found in
        browser software called a «cookie». Cookies are small files that your web browser places on
        your hard drive for record-keeping purposes. By showing how and when visitors use the Web
        site, cookies help us track user trends and patterns. They also prevent you from having to
        re-enter your preferences on certain areas of the Web site where you have entered preference
        information before. DRIVE.NET also may use pixel tags (single-pixel image files also known
        as transparent GIFs, clear GIFs or web beacons) to access cookies and to count users who
        visit the Web site or open our HTML-formatted e-mail messages. The information that is
        collected through the use of these image files is not personally identifiable and is used
        only to track responses to our advertising and promotional efforts.
      </Paragraph>
      <Paragraph>
        Another example of information that we automatically collect as you navigate our Web sites
        is information in «log files» about your computer’s connection to the Internet such as a
        user’s IP address (the number assigned automatically to your computer whenever you use the
        Internet), length of time spent on the Web sites, and the pages accessed during each visit
        to the Web sites. We use this information to analyze trends, administer the site, track user
        movement on the Web sites, and gather broad demographic information for aggregate use. IP
        addresses are not linked to personally identifiable information except if necessary to
        provide certain premium content or services for which you have registered.
      </Paragraph>
      <Paragraph>
        In general, aggregate information allows us to improve the delivery of our web pages to you
        and to measure traffic on the Web sites.
      </Paragraph>
      <Paragraph>
        In addition, third party advertisers or third party ad servers that place and present
        advertising on the Web site also may collect information from you via cookies, pixel tags or
        similar technologies. These third party advertisers and ad servers may use the information
        they collect to help present their advertisements, to help measure and research the
        advertisements’ effectiveness, or for other uses.
      </Paragraph>
      <Paragraph>
        Also, we integrate Google’s Invisible CAPTCHA (reCAPTCHA) software, that collects
        information about users for security reasons. Information collected by reCAPTCHA is held in
        accordance with the{' '}
        <a href='https://www.google.com/intl/en/policies/privacy/'>Google Privacy Policy</a>.
      </Paragraph>
      <Paragraph>
        Please be aware that we are not responsible for the privacy practices of such other sites.
        We encourage users to be aware that when they leave our Web sites for another site, even if
        through a link, they should read the privacy policies of each and every web site that
        collects personally identifiable information. The provision of external links to other Web
        sites is a common practice on the Internet and it in no way indicates that we have any
        responsibility or control over the linked company, its products, services, or Web site.
      </Paragraph>
      <Title level={3}>Other Aggregate Information</Title>
      <Paragraph>
        If we gather demographic information from you (for example, your age, education level or
        household income), we will not share that information in a manner that identifies you as an
        individual with any other third party. Any information that might be shared with third
        parties will be aggregate information only («aggregate» meaning demographic information
        speaking only to the broad characteristics of a group), unless we are required to release
        additional information under conditions described in next section «Legal Matters».
      </Paragraph>
      <Title level={3}>Legal Matters</Title>
      <Paragraph>
        Notwithstanding any other term or conclusion of any third party Privacy Policy, we reserve
        the right to comply with the law and release information concerning any user of our sites or
        services when we have grounds to believe that:
      </Paragraph>
      <ul>
        <li>
          (i) the user is in violation of our Terms of Use or other published guidelines or is
          engaged in any illegal activity,
        </li>
        <li>
          (ii) to release information in response to court and governmental orders, other
          enforceable requests from government entities, civil subpoenas, discovery requests and
          otherwise as required by law;
        </li>
        <li>
          (iii) we believe in good faith that such release is required to protect the rights,
          property, safety or security of DRIVE.NET, any of our users or the public,
        </li>
        <li>(iv) to respond to an emergency.</li>
      </ul>
      <Paragraph>
        In the event of a change of control of DRIVE.NET (such as through a merger, sale, assignment
        or liquidation of the business entity, or any of its properties, assets or equity) or, in
        the event of a direct or indirect sale of any of its publishing properties and/or its Web
        sites, personal information of users of the affected Web sites in our possession will be
        transferred to the new owner/successor. We are not obligated to notify Web site users of any
        such transaction.
      </Paragraph>
      <Paragraph>
        You may always change or delete your information or opt out by contacting us as provided
        below, or if the acquirer posts a new Privacy Policy with new contact information, you may
        change or delete your information or opt out by following any new instructions that are
        posted.
      </Paragraph>
      <Title level={3}>HOW DO I OPT OUT OR CHANGE MY PERSONAL INFORMATION?</Title>
      <Paragraph>
        To opt out of further e-mail communications from us, just click on the «opt out» controls in
        the e-mail, or contact us as instructed at the end of this Privacy Policy. We may need up to
        30 days to assure compliance with your request.
      </Paragraph>
      <Paragraph>
        To change your personal information that you have provided us, please contact us as
        instructed at the end of this Privacy Policy.
      </Paragraph>
      <Title level={3}>CHILDREN’S ON-LINE PRIVACY INFORMATION</Title>
      <Paragraph>
        This Web site are not intended for use by children under the age of 18. We do not knowingly
        collect information from children and minors. We encourage parents and legal guardians to
        monitor their children’s Internet usage and to help enforce our Privacy Policy by
        instructing their children to never provide information on our Web sites without their
        permission.
      </Paragraph>
      <Paragraph>
        Involvement of parents: In accordance with the provisions of the Children’s Online Privacy
        Protection Act, in the event that we do collect any personal information or data from
        children as a byproduct of Web site operation, it should be noted here that we publish
        content relating to collectibles and other areas of interest that may naturally appeal to a
        given child. However, if information is subsequently found to belong to a child under the
        age of 13, we will notify parents first, and will seek parental or guardian determination as
        to the use or deletion of such personal information.
      </Paragraph>
      <Paragraph>
        A parent also has the option to contact us and have deleted any of their child’s personal
        information that they find is in our possession. This can be done by contacting us as noted
        at the end of this Privacy Policy.
      </Paragraph>
      <Title level={3}>OUR COMMITMENT TO DATA SECURITY</Title>
      <Paragraph>
        We have put in place security hardware, software and software update and network scanning
        procedures designed to safeguard and secure the information under our control and follow
        generally accepted industry standards. We work with third party service providers and
        vendors that use encryption and authentication to maintain the confidentiality of your
        personal information. If stored, we house personal information on systems behind firewalls
        that are only accessible to limited personnel.
      </Paragraph>
      <Paragraph>
        Any employee with access to personally-identifying information is required to protect this
        information in a manner that is consistent with this Privacy Policy and may not use the
        information for any purpose other than to carry out the services they are performing for
        DRIVE.NET. These individuals may be subject to discipline, including termination and
        criminal prosecution, if they fail to meet these obligations.
      </Paragraph>
      <Paragraph>
        Although we will exercise high levels of care in providing secure transmission of
        information between your computer and our servers and in storing that information on our
        systems, no method of transmission over the Internet, or of electronic storage, is 100%
        secure. As such, we cannot ensure or otherwise guarantee the security of information
        transmitted to us over the Internet.
      </Paragraph>
      <Title level={3}>CHANGES OR UPDATES TO THIS PRIVACY POLICY</Title>
      <Paragraph>
        We may modify this privacy policy at any time, so please review it frequently. We will post
        any changes to the privacy policy on the Web sites, but we have no other obligation to
        notify you of such changes. We indicate the date of the current version of this privacy
        policy below, so you know when it was last updated.
      </Paragraph>
      <Paragraph>
        Any changes will affect all of the information we have previously collected from you, as
        well as the information we collect after such changes, and may include without limitation
        additional uses or disclosures of your information. If any of the changes represent a
        material change that expands our use of personal information, the changes will be posted in
        advance of taking effect.
      </Paragraph>
      <Paragraph>
        We may post a prompt on the home page of our site for users to review the new privacy policy
        but are not required to do so. If you object to the changes, please contact us as provided
        below and we will remove your information that we previously collected. However, your
        subsequent provision of information to us through any of our Web sites is deemed your
        agreement to use of that information in accordance with the then-current privacy policy.
      </Paragraph>
      <Title level={3}>CONTACT US</Title>
      <Paragraph>
        If you have any questions about this Privacy Policy or you wish to make changes to your
        personal information or remove yourself from our database, please contact us in one of the
        following ways:
      </Paragraph>
      <Paragraph>
        E-mail us at:{' '}
        <a href='mailto:help@hobius.com?subject=Re: Privacy Policy'>help@hobius.com</a> and insert
        only the words «Re: Privacy Policy» in the subject line header of the e-mail.
      </Paragraph>
      <Paragraph>Version: November 3, 2016</Paragraph>
    </StaticPage>
  );
};

export default Privacy;
