import {EntityId, UrlString} from 'shared/types';

export type FormatName = 'gallery-item' | 'gallery-item-with-watermark' | 'preview';

export type Formats = {
  [key in FormatName]?: UrlString;
};

export interface ClientImage {
  id: EntityId;
  formats: Formats;
}

export function selectFormat(formatName: FormatName) {
  return function selectConcrete(image: ClientImage): UrlString | undefined {
    return image.formats[formatName];
  };
}

export const galleryItem = selectFormat('gallery-item');
