import React from 'react';
import ProductList from 'components/ProductList';
import PageTitle from 'components/PageTitle';
import NavBar from 'components/NavBar';
import Translate from './translation.json';
import './style.css';

import {products} from 'domain/stubs';

const Dashboard = () => {
  return (
    <div className='dashboard'>
      <PageTitle>{Translate.all_products}</PageTitle>
      <ProductList products={products} />
      <NavBar />
    </div>
  );
};

export default Dashboard;
