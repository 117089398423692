import {PriceCents} from 'domain/product';
import {formatNumber} from 'utils/formatNumber';

export function formatPrice(amount: PriceCents): string {
  const options = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  };

  return formatNumber(amount / 100, {options});
}
