import React from 'react';
import {connect} from 'react-redux';
import {Form, Input} from 'antd';

import {StateIndex} from 'store/currentProduct';
import {ValidatableSectionProps, UpdateValueEvent} from '../types';
import {mapStateToProps, updateToProps as mapDispatchToProps} from '../connect';

import ConditionSelector from 'components/ConditionSelector';
import Attach from 'components/Attach';

import {injectIf} from 'utils/injectIf';
import Translate from './translation.json';

const Mandatory: React.FC<ValidatableSectionProps> = ({
  currentProduct: product,
  update,
  errors,
  removeError,
}) => {
  const updateValue = (e: UpdateValueEvent): void => {
    const {name, value} = e.target;
    removeError(name);
    return update(name as StateIndex, value);
  };

  return (
    <section>
      <Form.Item label={Translate.name} validateStatus={injectIf(!!errors.name, 'error')}>
        <Input
          maxLength={60}
          placeholder={Translate.name_placeholder}
          name='name'
          value={product.name}
          onChange={updateValue}
        />
      </Form.Item>

      <Form.Item
        label={Translate.description}
        validateStatus={injectIf(!!errors.description, 'error')}>
        <Input.TextArea
          placeholder={Translate.description_placeholder}
          autoSize={{minRows: 3}}
          name='description'
          value={product.description}
          onChange={updateValue}
        />
      </Form.Item>

      <Form.Item label={Translate.attachments}>
        <Attach onChange={(files) => console.log(files)} />
      </Form.Item>

      <Form.Item
        label={Translate.stockNumber}
        validateStatus={injectIf(!!errors.stockNumber, 'error')}>
        <Input
          placeholder='К1154'
          name='stockNumber'
          value={product.stockNumber}
          onChange={updateValue}
        />
      </Form.Item>

      <Form.Item label={Translate.weight} validateStatus={injectIf(!!errors.weight, 'error')}>
        <Input
          type='number'
          placeholder='2,5'
          name='weight'
          value={product.weight}
          onChange={updateValue}
        />
      </Form.Item>

      <Form.Item label={Translate.condition} validateStatus={injectIf(!!errors.condition, 'error')}>
        <ConditionSelector value={product.condition} onChange={updateValue} name='condition' />
      </Form.Item>

      <Form.Item label={Translate.price} validateStatus={injectIf(!!errors.price, 'error')}>
        <Input
          type='number'
          placeholder='100'
          name='price'
          value={product.price}
          onChange={updateValue}
        />
      </Form.Item>

      <Form.Item
        label={Translate.purchasePrice}
        validateStatus={injectIf(!!errors.purchasePrice, 'error')}>
        <Input
          type='number'
          placeholder='100'
          name='purchasePrice'
          value={product.purchasePrice}
          onChange={updateValue}
        />
      </Form.Item>
    </section>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Mandatory);
