import {EntityId, EmailString} from 'shared/types';

export enum UserRoles {
  Admin,
  User,
}

export interface User {
  id: EntityId;
  name: string;
  email: EmailString;
  roles: UserRoles[];
}
