export default [
  'Книги',
  'Журналы',
  'Комиксы',
  'Монеты и банкноты',
  'Часы',
  'Бытовая техника',
  'Игрушки',
  'Документы',
  'Музыка и видео',
  'Музыкальные инструменты',
  'Одежда, обувь, аксессуары',
  'Коллекционные карточки',
  'Предметы интерьера',
  'Пробки',
  'Официальный мерч ',
  'Посуда',
  'Талисманы',
];
